import { Accordion, Button, Col, Form, Row, Spinner, Tab } from "react-bootstrap";
import { determineTenantName } from "../../../utils/ALL/tenantUtils";
import { useState } from "react";
import { doPost } from "../../../utils/apiUtils";
import { getUserCountry, getUserTenantId } from "../../../utils/ALL/localStorageUtils";
import { Auth } from "aws-amplify";
import ICPSearchResultHandler from "../../SearchResults/ICPSearchResult/ICPSearchResultHandler";
import { log } from "../../../utils/ALL/loggingUtils";

const NZSearchView = () => {
  const [icp, setIcp] = useState("");
  const [icpSearchResult, setIcpSearchResult] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isFailedToRetrieveData, setIsFailedToRetrieveData] = useState(false);
  const handleIcpSearch = async (event) => {
    event.preventDefault();
    setIcpSearchResult([]);
    log(`Searching for ICP: ${icp}`);

    try {
      setIsLoading(true);
      setIsFailedToRetrieveData(false);
      const postResponse = await doPost(`${process.env.REACT_APP_BASE_API_ENDPOINT}/meterpointid`, {
        meterPointId: icp,
        country: getUserCountry(),
        tenantId: getUserTenantId(),
      });

      if (postResponse.status === 400) {
        log(postResponse.data);
        setIsFailedToRetrieveData(true);
      } else if (postResponse.status === 401) {
        alert("You are no longer authorized. Please LogIn again");
        return await Auth.signOut();
      } else if (postResponse.status === 200) {
        if (postResponse.data.length > 0) {
          setIcpSearchResult(postResponse.data);
        } else {
          setIsFailedToRetrieveData(true);
        }
      }
      setIsLoading(false);
    } catch (error) {
      log("error in getting ICP details");
      log(error);
      setIsLoading(false);
    }
  };
  return (
    <div className="search-form-wrapper">
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row className="search-row mt-3" style={{ marginBottom: "60px" }}>
          <Col sm={3} className="search-input-column">
            <div className="search-input-title" id={determineTenantName()}>
              Search Input
            </div>

            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header className="items-search-header">Basic Search</Accordion.Header>

                <Accordion.Body>
                  <Form>
                    <Form.Group>
                      <Form.Label>ICP</Form.Label>
                      <Form.Control
                        name="meterpointId"
                        type="text"
                        onChange={(e) => setIcp(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group className="search-button-div">
                      <Button type="submit" onClick={handleIcpSearch} className="mt-2 search-btn">
                        Search
                      </Button>
                    </Form.Group>
                  </Form>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col sm={9} className="search-result-column">
            <Tab.Content className="search-result-tab-content">
              <div className="search-results-title" id={determineTenantName()}>
                Search Results
              </div>
              <div className="empty-div">
                {isLoading ? (
                  <div className="spinner-div-searching">
                    <Spinner animation="border" variant="danger" className="spinner-searching" />
                  </div>
                ) : null}
                <ICPSearchResultHandler apiResponseData={icpSearchResult}></ICPSearchResultHandler>
                {isFailedToRetrieveData ? <div>No data was Retrieved</div> : null}
              </div>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};

export default NZSearchView;
