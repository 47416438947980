export const DEFAULT_SORTED_1 = [
  {
    dataField: "lastUpdated",
    order: "desc",
  },
];

export const DEFAULT_SORTED_2 = [
  {
    dataField: "dateInitiated",
    order: "desc",
  },
];

export const DEFAULT_SORTED_3 = [
  {
    dataField: "dateCreated",
    order: "desc",
  },
];

export const pageButtonRenderer = (data) => {
  let { page, active, disabled, title, onPageChange } = data;

  const onClick = (e) => {
    e.preventDefault();
    onPageChange(page);
  };

  let activeStyle = {};
  if (active) {
    activeStyle.backgroundColor = "black";
    activeStyle.color = "white";
  } else {
    activeStyle.backgroundColor = "white";
    activeStyle.color = "gray";
  }
  return (
    <li key={page} className="page-item">
      <a style={activeStyle} onClick={onClick}>
        {page}
      </a>
    </li>
  );
};

export const PAGINATION_OPTIONS_1 = {
  sizePerPage: 10, // Specify the size per page. It's necessary when remote is enabled
  pageStartIndex: 1, // first page will be 0, default is 1
  paginationSize: 5, // the pagination bar size, default is 5
  showTotal: true, // display pagination information
  withFirstAndLast: true, // hide the going to first and last page button
  alwaysShowAllBtns: true, // always show the next and previous page button
  firstPageText: "First", // the text of first page button
  prePageText: "Prev", // the text of previous page button
  nextPageText: "Next", // the text of next page button
  lastPageText: "Last", // the text of last page button
  nextPageTitle: "Go to next", // the title of next page button
  prePageTitle: "Go to previous", // the title of previous page button
  firstPageTitle: "Go to first", // the title of first page button
  lastPageTitle: "Go to last", // the title of last page button
  hideSizePerPage: true, // hide the size per page dropdown
  hidePageListOnlyOnePage: true, // hide pagination bar when only one page, default is false
  pageButtonRenderer,
};
