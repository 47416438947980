import { Button, Col, Container, Image, Row, Tab } from "react-bootstrap";
import "./UnrecognizedPath.css";
import { useNavigate } from "react-router-dom";
const UnrecognizedPath = () => {
  const navigate = useNavigate();
  return (
    <Container className="error-page-container">
      <Row className="error-page-row mt-3" style={{ marginBottom: "60px", backgroundColor: "black" }}>
        <Col className="error-page-column">
          <div className="error-page-row error-page-text">
            <Image src="/images/error404.png" style={{ width: "200px", height: "auto" }}></Image>
          </div>
          <div className="error-page-row error-page-text">ERROR 404</div>
          <br></br>
          <div className="error-page-row error-page-text">Sorry, Page Not Found</div>
          <br></br>
          <div className="error-page-row error-page-text">
            <Button
              className="go-back-btn"
              onClick={() => {
                navigate("/");
              }}
            >
              Go back to Homepage
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default UnrecognizedPath;
