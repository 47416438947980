import { Auth } from "aws-amplify";
import React from "react";
import { Button, Col, Container, Form, Image, ListGroup, Row } from "react-bootstrap";
import "./RealmsPage.css";
import { getUserTenantId } from "../../utils/ALL/localStorageUtils";
import { log } from "../../utils/ALL/loggingUtils";
const RealmsPage = ({ setSignInPage }) => {
  const determineTenantName = () => {
    const tenantId = getUserTenantId();

    switch (tenantId) {
      case "1001":
        return "Origin Energy";
      case "1002":
        return "Nectr";
      case "1003":
        return "Octopus Energy";
      case "1005":
        return "Energy Queensland";

      default:
        return null;
    }
  };

  const onClickSignIn = () => {
    const tenantId = getUserTenantId();

    if (tenantId === "1003") {
      setSignInPage("user-pass-login");
    } else if (tenantId === "1005") {
      if (process.env.REACT_APP_ENVI.toUpperCase() === "DEV" || process.env.REACT_APP_ENVI.toUpperCase() === "TEST") {
        return setSignInPage("user-pass-login");
      } else {
        return Auth.federatedSignIn({ provider: process.env.REACT_APP_IDENTITY_PROVIDER });
      }
    } else {
      log(`${tenantId} - no signIn functionality set`);
    }
  };

  const accentureLogIn = () => {
    log("accenture log in");
  };
  return (
    <>
      <Container className="realms-page-container">
        <Row className="realms-page-row">
          <Col className="realms-page-left-column">
            <Image src="/images/realmsPageCover.png" className="realms-page-cover-image"></Image>
          </Col>

          <Col className="realms-page-right-column">
            <Row className="realms-page-right-column-row1">
              <div style={{ height: "10vh" }}></div>
              {/* <Image
                src="/images/accenture/Acc_Logo_Black_Purple_RGB.png"
                alt="acc_logo_white_purple.png"
                className="realms-page-acn-logo"
                // width={300}
                // height="auto"
              ></Image> */}

              <table className="reals-page-table-banner">
                <tbody>
                  <tr>
                    <td>
                      <Image
                        src="/images/accenture/Acc_Logo_Black_Purple_RGB.png"
                        alt="acc_logo_white_purple.png"
                        className="realms-page-acn-logo"
                      ></Image>
                    </td>
                    <td>
                      <div className="realms-page-vertical-line"></div>
                    </td>
                    <td className="reals-page-mdh-text">Market Data Hub</td>
                  </tr>
                </tbody>
              </table>

              <div style={{ height: "20vh" }}></div>
              <div>Sign in with one of these accounts</div>
              <ListGroup>
                <ListGroup.Item className="realms-page-list-item" onClick={onClickSignIn}>
                  <Image src="/images/logInIcon.png" width={40} height="auto"></Image> {determineTenantName()}
                </ListGroup.Item>
              </ListGroup>
            </Row>
            <Row className="realms-page-right-column-row2">
              <div className="empty-div-bottom" style={{ width: "75%" }}></div>
              <div className="alternative-sign-in-div" style={{ width: "25%" }}>
                Alternative Sign In option
                <ListGroup.Item className="realms-page-list-item2" onClick={accentureLogIn}>
                  <Image src="/images/logInIcon.png" width={40} height="auto"></Image> Accenture
                </ListGroup.Item>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RealmsPage;
