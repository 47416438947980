import { getUserCountry } from "../../../utils/ALL/localStorageUtils";
import AUSearchNavBarHandler from "./AU/AUSearchNavBarHandler";
import NZSearchNavBarHandler from "./NZ/NZSearchNavBarHandler";

const SearchNavigationBarHandler = ({ activeNav, setActiveNav }) => {
  const userCountry = getUserCountry().toUpperCase();
  if (userCountry === "AU") {
    return <AUSearchNavBarHandler></AUSearchNavBarHandler>;
  } else if (userCountry === "NZ") {
    return <NZSearchNavBarHandler activeNav={activeNav} setActiveNav={setActiveNav}></NZSearchNavBarHandler>;
  } else {
    return null;
  }
};

export default SearchNavigationBarHandler;
