export const auAbbreviations = [
  {
    abbreviation: "NEMMCO",
    meaning: "Australian Energy Market Operator Limited",
  },
  {
    abbreviation: "ORIGIN",
    meaning: "Origin Energy Electricity Limited",
  },
  {
    abbreviation: "EEQ",
    meaning: "Energy Queensland Limited",
  },
];

export const TXN_GROUP_CATS = "CATS";
export const TXN_GROUP_CUST = "CUST";
export const TXN_GROUP_MDMT = "MDMT";
export const TXN_GROUP_MTRD = "MTRD";
export const TXN_GROUP_NMID = "NMID";
export const TXN_GROUP_NPNX = "NPNX";
export const TXN_GROUP_OWNX = "OWNX";
export const TXN_GROUP_OWNP = "OWNP";
export const TXN_GROUP_SITE = "SITE";
export const TXN_GROUP_SORD = "SORD";
export const TXN_GROUP_FLDW = "FLDW";
export const TXN_GROUP_IAIT = "IAIT";
export const TXN_GROUP_NETB = "NETB";
export const TXN_GROUP_ERFT = "ERFT";
export const TXN_GROUP_DPRT = "DPRT";

export const AU_TXN_GROUPS = ["CATS", "CUST", "MDMT", "MTRD", "NMID", "NPNX", "OWNX", "SITE", "SORD"];

export const EQL_PARTICIPANTS = ["EEQ", "EEQLILY", "EEQGEN", "EEQMEWF"];

export const DATE_TIME_FORMAT_1 = "yyyy.MM.DD.HH.mm";

export const DATE_TIME_FORMAT_2 = "DD MMMM YYYY HH:mm";

export const FUEL_TYPES = [
  { name: "Electricity", value: "elec" },
  { name: "Gas", value: "gas" },
];

export const FUEL_TYPE_ELEC = "Elec";
export const FUEL_TYPE_GAS = "Gas";
