import React from "react";
import { Navbar } from "react-bootstrap";
import "./HeaderSignIn.css";
const HeaderSignIn = () => {
  return (
    <header data-test="header-sign-in">
      <Navbar bg="dark" variant="dark" className="header-sign-in"></Navbar>
    </header>
  );
};

export default HeaderSignIn;
