import { NavLink } from "react-router-dom";

const AUCommonSearchNav = () => {
  return (
    <NavLink className="header-active" href="/search" exact="true" to="/search">
      Search
    </NavLink>
  );
};

export default AUCommonSearchNav;
