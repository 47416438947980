import { getUserCountry } from "../../utils/ALL/localStorageUtils";
import AUSearchView from "./AU/AUSearchView";
import NZSearchView from "./NZ/NZSearchView";
import "./Search.css";
const SearchViewHandler = () => {
  const userCountry = getUserCountry().toUpperCase();
  if (userCountry === "AU") {
    return <AUSearchView></AUSearchView>;
  } else if (userCountry === "NZ") {
    return <NZSearchView></NZSearchView>;
  } else {
    return null;
  }
};

export default SearchViewHandler;
