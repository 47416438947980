import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { TENANT_1001, TENANT_1002, determineTenantName } from "../../../../utils/ALL/tenantUtils";
import { getUserTenantId } from "../../../../utils/ALL/localStorageUtils";
import { DEFAULT_SORTED_2, PAGINATION_OPTIONS_1 } from "../../../../utils/bootStrapTableUtils";
import paginationFactory from "react-bootstrap-table2-paginator";
import "./NMISearchResultCommon.css";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import {
  DATE_CREATED,
  DATE_CREATED_AEDT,
  DATE_CREATED_AEST,
  DATE_FORMAT_3,
  DATE_FORMAT_6,
  convertUtcDateTimeToAedtAndFormat,
  convertUtcDateTimeToAestAndFormat,
  formatDateTimeTypeA,
  isAuSydneyInDST,
} from "../../../../utils/dateTimeUtils";
import { auMasterTxnStatusFormatter } from "../../../../utils/AU/auTxnStatusUtils";
import { FUEL_TYPE_ELEC, FUEL_TYPE_GAS } from "../../../../utils/AU/constantAU";
import { rowStyler } from "./NMISearchCommonUtils";
import { SEARCH_DATA_TYPE_ACTIVE, SEARCH_DATA_TYPE_HISTORICAL } from "../../../Search/AU/AUSearchViewUtils";

const { ExportCSVButton } = CSVExport;
const NMISearchResultCommon = ({
  apiResponseData,
  fuelType,
  searchDataType,
  searchResultFuelType,
  isFailedToRetrieveData,
  errorMessage,
}) => {
  const TENANT_ID = getUserTenantId();

  const determineTableTitle = () => {
    let dataType = searchDataType.toLowerCase();
    if (dataType === SEARCH_DATA_TYPE_ACTIVE) {
      return "Active Search Results";
    } else if (dataType === SEARCH_DATA_TYPE_HISTORICAL) {
      return "Historical Search Results";
    }
  };

  const masterTransactionIdFormatter = (cell, row) => {
    /** Uses if statement, as other tenants might use the same url */

    if (TENANT_ID === TENANT_1001) {
      let url = `/masterTxnDetails?masterTxnId=${row.masterTxnId}&status=${row.status}&sender=${row.sender}&nmi=${row.meterPointID}&fuel=${fuelType}`;
      if (searchDataType.toLowerCase() === SEARCH_DATA_TYPE_HISTORICAL) {
        url = url + `&dbName=historical&txnGroup=${row.txnGroup}`;
      }
      return (
        <a
          onClick={() => {
            window.open(url);
          }}
        >
          {cell}
        </a>
      );
    } else if (TENANT_ID === TENANT_1002) {
      return (
        <a
          onClick={() => {
            window.open(
              `/masterTxnDetails?masterTxnId=${row.masterTxnId}&status=${row.status}&sender=${row.sender}&nmi=${row.meterPointID}`
            );
          }}
        >
          {cell}
        </a>
      );
    } else {
      return null;
    }
  };

  const dateCreatedFormatter = (cell, row) => {
    /** Uses if statement, as other tenants might use the same url */

    if (TENANT_ID === TENANT_1001) {
      return formatDateTimeTypeA(cell, DATE_FORMAT_3);
    } else if (TENANT_ID === TENANT_1002) {
      if (isAuSydneyInDST()) {
        return convertUtcDateTimeToAedtAndFormat(cell, DATE_FORMAT_3);
      } else {
        return convertUtcDateTimeToAestAndFormat(cell, DATE_FORMAT_3);
      }
    } else {
      return cell;
    }
  };

  const dateCreatedColumnNameFormatter = () => {
    /** Uses if statement, as other tenants might use the column header */

    if (TENANT_ID === TENANT_1002) {
      if (isAuSydneyInDST()) {
        return DATE_CREATED_AEDT;
      } else {
        /**Tenants that is always AEST AU timezone */
        return DATE_CREATED_AEST;
      }
    } else {
      /**Tenants that is not define */
      return DATE_CREATED;
    }
  };

  const auMarketFormatter = (cell, row) => {
    if (searchResultFuelType === FUEL_TYPE_ELEC.toLowerCase()) {
      return "NEM";
    } else if (searchResultFuelType === FUEL_TYPE_GAS.toLowerCase()) {
      return row.market;
    } else {
      return "";
    }
  };

  const columns = [
    {
      dataField: "masterTxnId",
      text: "Master Transaction ID",
      formatter: masterTransactionIdFormatter,
      classes: "master-txn-id-td",
      csvExport: true,
    },
    {
      dataField: "meterPointID",
      text: searchResultFuelType === FUEL_TYPE_ELEC.toLowerCase() ? "NMI" : "MIRN",
    },
    {
      dataField: "dateInitiated",
      text: dateCreatedColumnNameFormatter(),
      formatter: dateCreatedFormatter,
      sort: true,
    },
    {
      dataField: "abc",
      text: "Market",
      formatter: auMarketFormatter,
    },
    {
      dataField: "masterTxnType",
      text: "Transaction Type",
    },

    {
      dataField: "status",
      text: "Status",
      formatter: auMasterTxnStatusFormatter,
    },

    {
      dataField: "sender",
      text: "Source",
    },
  ];

  if (apiResponseData.length > 0) {
    return (
      <>
        <div className="wrapper-div">
          <div className={`${determineTenantName()}-nmi-search-data-type-header`}>{determineTableTitle()}</div>
          <div className="search-result-div">
            {apiResponseData.length > 0 ? (
              <ToolkitProvider
                columns={columns}
                data={apiResponseData}
                bootstrap4
                rowStyle={rowStyler}
                defaultSorted={DEFAULT_SORTED_2}
                data-test="search-result-table"
                exportCSV
              >
                {(props) => (
                  <div>
                    <BootstrapTable
                      {...props.baseProps}
                      classes="search-result-table"
                      wrapperClasses="search-result-wrapper"
                      headerWrapperClasses={determineTenantName()}
                      pagination={paginationFactory(PAGINATION_OPTIONS_1)}
                      keyField={"masterTxnId"}
                      defaultSorted={DEFAULT_SORTED_2}
                    />
                    {searchDataType.toLowerCase() === "historical" ? (
                      <div className="export-csv-button-div-wrapper">
                        <ExportCSVButton {...props.csvProps} className="csv-export-button">
                          Export to CSV
                        </ExportCSVButton>
                      </div>
                    ) : null}
                  </div>
                )}
              </ToolkitProvider>
            ) : null}
          </div>
        </div>
      </>
    );
  } else {
    return <>{isFailedToRetrieveData ? <div>{errorMessage}</div> : <div>No result found</div>} </>;
  }
};

export default NMISearchResultCommon;
