import React, { useEffect, useState } from "react";
import { TENANT_1001, determineTenantName } from "../../../../utils/ALL/tenantUtils";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { doPost } from "../../../../utils/apiUtils";
import { getUserCountry, getUserTenantId } from "../../../../utils/ALL/localStorageUtils";
import { useSearchParams } from "react-router-dom";
import AUTxnFlowDiagram from "../../TxnFlowDiagram/AUTxnFlowDiagram";
import AUMasterTransactionHandler from "../../MasterTransaction/AU/AUMasterTransactionHandler";

import AURejectionDetails from "../../RejectionDetails/AU/AURejectionDetails";
import AUObjectionDetails from "../../ObjectionDetails/AU/AUObjectionDetails";
import AUAssociatedTransactionsHandler from "../../AssociatedTransactions/AU/AUAssociatedTransactionsHandler";

const AUMasterTxnDetailViewContainer = () => {
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [isApiError, setIsApiError] = useState(false);
  const [searchParams] = useSearchParams();
  const [urlParams, setUrlParams] = useState(() => {
    return Object.fromEntries([...searchParams]);
  });

  const [associatedTransactions, setAssociatedTransactions] = useState([]);
  useEffect(() => {
    fetchAssociatedTransactions();
  }, []);
  const fetchAssociatedTransactions = async () => {
    try {
      setIsFetchingData(true);
      setAssociatedTransactions([]);

      let tenantId = getUserTenantId();

      let params = {};
      if (tenantId === TENANT_1001) {
        params = {
          masterTxnId: urlParams.masterTxnId,
          country: getUserCountry(),
          tenantId: getUserTenantId(),
          dbName: urlParams.dbName,
          fuelType: urlParams.fuel,
          txnGroup: urlParams.txnGroup,
        };
      } else {
        params = {
          masterTxnId: urlParams.masterTxnId,
          country: getUserCountry(),
          tenantId: getUserTenantId(),
        };
      }

      let endpoint = "";
      if (urlParams.dbName === "historical") {
        endpoint = "/view/historical/mastertxnid";
      } else {
        endpoint = "/view/mastertxnid";
      }

      const postResponse = await doPost(`${process.env.REACT_APP_BASE_API_ENDPOINT}${endpoint}`, params);

      if (postResponse.status === 400) {
        setAssociatedTransactions([]);
        setIsApiError(true);
      } else if (postResponse.status === 401) {
        alert("You are no longer authorized. Please LogIn again");
      } else if (postResponse.status === 200) {
        setAssociatedTransactions(postResponse.data);
        setIsApiError(false);
      }
      setIsFetchingData(false);
    } catch (err) {
      setAssociatedTransactions([]);
      setIsFetchingData(false);
      console.error(err);
    }
  };

  return (
    <div className="background-image-wrapper" id={determineTenantName()}>
      <Container fluid className="master-txn-container-wrapper">
        <Row>
          <Col className="search-results-title" id={determineTenantName()}>
            Transaction Details
          </Col>
        </Row>

        {!isFetchingData && isApiError ? (
          <Row className="spinner-div master-txn-view-spinner-wrapper">Error on retrieving data.</Row>
        ) : (
          <>
            {isFetchingData ? (
              <Row className="spinner-div master-txn-view-spinner-wrapper">
                <Col className="spinner-col">
                  <Spinner animation="border" variant="danger" className="spinner-master-txn-viewer" />
                </Col>
              </Row>
            ) : (
              <>
                {associatedTransactions.length > 0 ? (
                  <>
                    <AUTxnFlowDiagram
                      fuelType={urlParams.fuel}
                      txnStatus={urlParams.status}
                      txnGroup={associatedTransactions[0].txnGroup.toUpperCase()}
                    ></AUTxnFlowDiagram>

                    <AUMasterTransactionHandler
                      fuelType={urlParams.fuel}
                      transactions={associatedTransactions}
                      nmi={urlParams.nmi}
                      masterTransactionTxnType={urlParams.txnType}
                      masterTxnId={urlParams.masterTxnId}
                      associatedTransactions={associatedTransactions}
                    ></AUMasterTransactionHandler>

                    <AURejectionDetails></AURejectionDetails>
                    <AUObjectionDetails></AUObjectionDetails>

                    <AUAssociatedTransactionsHandler
                      associatedTransactions={associatedTransactions}
                      dbName={urlParams.dbName}
                    ></AUAssociatedTransactionsHandler>
                  </>
                ) : null}
              </>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default AUMasterTxnDetailViewContainer;
