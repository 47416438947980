export const ENV_DEV = "dev";
export const ENV_TEST = "test";
export const ENV_TEST2 = "test2";
export const ENV_PROD = "prod";

const isAllowedToLog = () => {
  return process.env.REACT_APP_ENVI === ENV_DEV || process.env.REACT_APP_ENVI === ENV_TEST;
};

export const log = (message) => {
  return isAllowedToLog() ? console.log(message) : null;
};

export const logStringifyJson = (json) => {
  return isAllowedToLog() ? console.log(JSON.stringify(json)) : null;
};
