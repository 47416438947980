import React from "react";
import { Col, Row, Table } from "react-bootstrap";

const AURejectionDetails = () => {
  return (
    <Row className="master-transaction-row master-txn-wrapper-child" data-test="master-transaction-component">
      <Col>
        <Table size="sm" borderless>
          <thead>
            <tr>
              <td>
                <strong>Objection Details</strong>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Objection Date:</td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default AURejectionDetails;
