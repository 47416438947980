import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import "./FooterSignIn.css";
const FooterSignIn = () => {
  return (
    <div>
      <Navbar fixed="bottom" data-test="footer-sign-in">
        <Nav className="ms-auto footer-sign-in">© {new Date().getFullYear()} Accenture. All Rights Reserved.</Nav>
      </Navbar>
    </div>
  );
};

export default FooterSignIn;
