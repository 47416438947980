import { Col, Image, Row } from "react-bootstrap";
import "./TxnFlowDiagram.css";
const NZTxnFlowDiagram = ({ txnStatus, txnType }) => {
  const transactionTypeImageSelector = () => {
    if (txnType.toUpperCase() === "MTRD") {
      return meterReadTransactionFlowImage();
    } else {
      return switchTransactionFlowImage();
    }
  };

  const meterReadTransactionFlowImage = () => {
    switch (txnStatus.toUpperCase()) {
      case "DOWNLOADED":
        return (
          <Image
            src="/images/NZ/ELEC/mtrd/DOWNLOADED.png"
            alt="DOWNLOADED.png"
            size="100"
            height="auto"
            data-test="nz-mtrd-downloaded-txn-flow"
          ></Image>
        );
      case "UPLOADED":
        return (
          <Image
            src="/images/NZ/ELEC/mtrd/UPLOADED.png"
            alt="UPLOADED.png"
            size="100"
            height="auto"
            data-test="nz-mtrd-uploaded-txn-flow"
          ></Image>
        );
      case "PROCESSING":
        return (
          <Image
            src="/images/NZ/ELEC/mtrd/PROCESSING.png"
            alt="PROCESSING.png"
            size="100"
            height="auto"
            data-test="nz-mtrd-processing-txn-flow"
          ></Image>
        );
      case "INVALID":
        return (
          <Image
            src="/images/NZ/ELEC/mtrd/INVALID.png"
            alt="INVALID.png"
            size="100"
            height="auto"
            data-test="nz-mtrd-invalid-txn-flow"
          ></Image>
        );
      case "COM":
        return (
          <Image
            src="/images/NZ/ELEC/mtrd/DELIVERED.png"
            alt="DELIVERED.png"
            size="100"
            height="auto"
            data-test="nz-mtrd-complete-txn-flow"
          ></Image>
        );
      case "DELIVERED":
        return (
          <Image
            src="/images/NZ/ELEC/mtrd/DELIVERED.png"
            alt="DELIVERED.png"
            size="100"
            height="auto"
            data-test="nz-mtrd-delivered-txn-flow"
          ></Image>
        );
      default:
        return <Image src="/images/NZ/ELEC/mtrd/DELIVERED.png" alt="DELIVERED.png" size="100" height="auto"></Image>;
    }
  };

  const switchTransactionFlowImage = () => {
    switch (txnStatus.toUpperCase()) {
      case "REQ":
        return (
          <Image
            src="/images/NZ/ELEC/switch/REQUESTED.png"
            alt="REQUESTED.png"
            size="100"
            height="auto"
            data-test="nz-switch-requested-txn-flow"
          ></Image>
        );
      case "INPROG":
        return (
          <Image
            src="/images/NZ/ELEC/switch/INPROGRESS.png"
            alt="INPROGRESS.png"
            size="100"
            height="auto"
            data-test="nz-switch-in-progress-txn-flow"
          ></Image>
        );
      case "COM":
        return (
          <Image
            src="/images/NZ/ELEC/switch/COMPLETED.png"
            alt="COMPLETED.png"
            size="100"
            height="auto"
            data-test="nz-switch-complete-txn-flow"
          ></Image>
        );
      case "CANCELLATIONREQUESTED":
        return (
          <Image
            src="/images/NZ/ELEC/switch/CANCELLATIONREQUESTED.png"
            alt="CANCELLATIONREQUESTED.png"
            size="100"
            height="auto"
            data-test="nz-switch-cancellation-requested-txn-flow"
          ></Image>
        );
      case "CANCELLATIONREJECTED":
        return (
          <Image
            src="/images/NZ/ELEC/switch/CANCELLATIONREJECTED.png"
            alt="CANCELLATIONREJECTED.png"
            size="100"
            height="auto"
            data-test="nz-switch-cancellation-rejected-txn-flow"
          ></Image>
        );
      case "REPLACEMENTREADREQUESTED":
        return (
          <Image
            src="/images/NZ/ELEC/switch/REPLACEMENTREADREQUESTED.png"
            alt="REPLACEMENTREADREQUESTED.png"
            size="100"
            height="auto"
            data-test="nz-switch-replacement-read-requested-txn-flow"
          ></Image>
        );
      case "REPLACEMENTREADACCEPTED":
        return (
          <Image
            src="/images/NZ/ELEC/switch/REPLACEMENTREADACCEPTED.png"
            alt="REPLACEMENTREADACCEPTED.png"
            size="100"
            height="auto"
            data-test="nz-switch-replacement-read-accepted-txn-flow"
          ></Image>
        );
      case "REPLACEMENTREADREJECTED":
        return (
          <Image
            src="/images/NZ/ELEC/switch/REPLACEMENTREADREJECTED.png"
            alt="REPLACEMENTREADREJECTED.png"
            size="100"
            height="auto"
            data-test="nz-switch-replacement-read-rejected-txn-flow"
          ></Image>
        );
      case "CANCELLED":
        return (
          <Image
            src="/images/NZ/ELEC/switch/CANCELLED.png"
            alt="CANCELLED.png"
            size="100"
            height="auto"
            data-test="nz-switch-cancelled-txn-flow"
          ></Image>
        );
    }
  };
  return (
    <Row className="status-image-row master-txn-wrapper-child" data-test="nz-display-txn-flow-status-row">
      <Col style={{ textAlign: "center" }}>
        <div style={{ textAlign: "left" }}>
          <strong>Results from Electricity Database</strong>
        </div>
        {transactionTypeImageSelector()}
      </Col>
    </Row>
  );
};

export default NZTxnFlowDiagram;
