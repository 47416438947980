import { Col, Row } from "react-bootstrap";

const NZObjectionDetails = ({ txnType }) => {
  if (txnType.toUpperCase() === "SWITCH") {
    return (
      <Row className="objections-details-row master-txn-wrapper-child" data-test="objection-details-row">
        <Col>
          <table>
            <tbody>
              <tr>
                <strong>Objection Details</strong>
              </tr>
              <tr>
                <td>Rejection Date:</td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
    );
  } else {
    return null;
  }
};

export default NZObjectionDetails;
