import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { determineTenantName } from "../../../../../utils/ALL/tenantUtils";
import "./AssociatedTransactions.css";
import { defaultSorted, formatDateCreatedUTC, rowStyler, senderFormatter } from "./NZAssociatedTransactionsUtils";
import { getUserCountry, getUserTenantId } from "../../../../../utils/ALL/localStorageUtils";
import { doPost } from "../../../../../utils/apiUtils";
import FileViewer from "../../Common/FileViewer/FileViewer";

const NZAssociatedTransactions = ({ associatedTransactions }) => {
  const [isFetchingS3Data, setIsFetchingS3Data] = useState(false);
  const [show, setShow] = useState(false);

  const [currentTxnId, setCurrentTxnId] = useState("");

  const [fileContent, setFileContent] = useState("");

  const [currentFileType, setCurrentFileType] = useState("");
  const [showUnavailable, setShowUnavailable] = useState(false);
  const handleCloseUnavailable = () => setShowUnavailable(false);
  const handleShowUnavailable = () => setShowUnavailable(true);
  const getFile = async (row, fileType) => {
    setShow(true);
    setIsFetchingS3Data(true);
    setCurrentFileType(fileType.toLowerCase());
    let request = {
      transactionId: row.transaction_id,
      txnSortKey: row.txn_sort_key,
      fileType: fileType,
      country: getUserCountry(),
      tenantId: getUserTenantId(),
    };

    setCurrentTxnId(row.transaction_id);

    const postResponse = await doPost(`${process.env.REACT_APP_BASE_API_ENDPOINT}/transactionid`, request);

    setIsFetchingS3Data(false);
    setFileContent(postResponse.data);
  };

  const viewDataFormatter = (cell, row) => {
    return (
      <span className="view-data-span">
        {" "}
        <a
          className="view-data-link"
          onClick={() => {
            getFile(row, "json");
          }}
        >
          <strong>JSON</strong>
        </a>{" "}
        |{" "}
        <a
          className="view-data-link"
          onClick={() => {
            getFile(row, "csv");
          }}
        >
          <strong>CSV</strong>
        </a>{" "}
        |{" "}
        <a className="view-data-link" onClick={handleShowUnavailable}>
          <strong>XML</strong>
        </a>
      </span>
    );
  };
  const columns = [
    {
      dataField: "transaction_id",
      text: "Transaction ID",
    },
    {
      dataField: "abc", //added so that the console will throw error
      text: "View Data",
      formatter: viewDataFormatter,
    },
    {
      dataField: "master_txn_id",
      text: "Master Transaction ID",
    },
    {
      dataField: "date_created",
      text: "Date Created [UTC]",
      formatter: formatDateCreatedUTC,
      sort: true,
    },
    {
      dataField: "transaction_type",
      text: "Transaction Type",
    },
    {
      dataField: "sender",
      text: "Sender",
      formatter: senderFormatter,
    },
    {
      dataField: "receiver",
      text: "Receiver",
    },
    {
      dataField: "status",
      text: "Status",
    },
    {
      dataField: "def", //added so that the console will throw error
      text: "Transfer Date",
    },
    {
      dataField: "init_transaction_id",
      text: "Initiating Transaction ID",
    },
  ];

  return (
    <>
      <Row className="associated-txn-row master-txn-wrapper-child">
        <Col>
          <div className="associated-txn-title">
            <strong>Associated Transactions</strong>
          </div>
          <BootstrapTable
            keyField="date_created"
            columns={columns}
            data={associatedTransactions}
            // pagination={paginationFactory(paginationOptions)}
            wrapperClasses="associated-txn-wrapper"
            rowStyle={rowStyler}
            classes="associated-transactions-table"
            defaultSorted={defaultSorted}
            headerWrapperClasses={determineTenantName()}
          />
        </Col>
      </Row>
      <FileViewer
        fileType={currentFileType}
        fileContent={fileContent}
        transactionId={currentTxnId}
        showModal={show}
        isFetchingS3Data={isFetchingS3Data}
        setShowModal={setShow}
      ></FileViewer>

      <Modal show={showUnavailable} onHide={handleCloseUnavailable}>
        <Modal.Body>This feature is unavailable</Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUnavailable}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NZAssociatedTransactions;
