import { Col, Container, Row, Spinner } from "react-bootstrap";

import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getUserCountry, getUserTenantId } from "../../../../utils/ALL/localStorageUtils";
import { determineTenantName } from "../../../../utils/ALL/tenantUtils";
import { doPost } from "../../../../utils/apiUtils";
import NZTxnFlowDiagram from "../../TxnFlowDiagram/NZTxnFlowDiagram";
import NZMasterTransaction from "../../MasterTransaction/NZ/NZMasterTransaction";
import NZObjectionDetails from "../../ObjectionDetails/NZ/NZObjectionDetails";
import NZAssociatedTransactionsHandler from "../../AssociatedTransactions/NZ/NZAssociatedTransactionsHandler";

const NZMasterTxnDetailViewContainer = () => {
  useEffect(() => {
    fetchAssociatedTransactions();
  }, []);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [searchParams] = useSearchParams();
  const [urlParams, setUrlParams] = useState(() => {
    return Object.fromEntries([...searchParams]);
  });
  const [isApiError, setIsApiError] = useState(false);
  const [associatedTransactions, setAssociatedTransactions] = useState([]);
  const fetchAssociatedTransactions = async () => {
    try {
      setIsFetchingData(true);
      setAssociatedTransactions([]);
      const postResponse = await doPost(`${process.env.REACT_APP_BASE_API_ENDPOINT}/mastertxnid`, {
        masterTxnId: urlParams.masterTxnId,
        country: getUserCountry(),
        tenantId: getUserTenantId(),
      });

      if (postResponse.status === 400) {
        setAssociatedTransactions([]);
        setIsApiError(true);
      } else if (postResponse.status === 401) {
        alert("You are no longer authorized. Please LogIn again");
      } else if (postResponse.status === 200) {
        setAssociatedTransactions(postResponse.data);
        setIsApiError(false);
      }
      setIsFetchingData(false);
    } catch (err) {
      setAssociatedTransactions([]);
      console.error(err);
    }
  };
  return (
    <div className="background-image-wrapper" id={determineTenantName()}>
      <Container fluid className="master-txn-container-wrapper">
        <Row>
          <Col className="search-results-title" id={determineTenantName()}>
            Transaction Details
          </Col>
        </Row>

        {!isFetchingData && isApiError ? (
          <Row className="spinner-div master-txn-view-spinner-wrapper">Error on retrieving data.</Row>
        ) : (
          <>
            {isFetchingData ? (
              <Row className="spinner-div master-txn-view-spinner-wrapper">
                <Col className="spinner-col">
                  <Spinner animation="border" variant="danger" className="spinner-master-txn-viewer" />
                </Col>
              </Row>
            ) : (
              <>
                {associatedTransactions.length > 0 ? (
                  <>
                    <NZTxnFlowDiagram txnStatus={urlParams.status} txnType={urlParams.txnType}></NZTxnFlowDiagram>

                    <NZMasterTransaction
                      transactions={associatedTransactions}
                      icp={urlParams.icp}
                      masterTransactionTxnType={urlParams.txnType}
                    ></NZMasterTransaction>

                    <NZObjectionDetails txnType={urlParams.txnType}></NZObjectionDetails>

                    <NZAssociatedTransactionsHandler
                      associatedTransactions={associatedTransactions}
                    ></NZAssociatedTransactionsHandler>
                  </>
                ) : null}
              </>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default NZMasterTxnDetailViewContainer;
