import { getUserTenantId } from "../../../utils/ALL/localStorageUtils";
import { TENANT_1005 } from "../../../utils/ALL/tenantUtils";
import EQLNmiSearchResult from "./1005/EQLNmiSearchResult";
import NMISearchResultCommon from "./Common/NMISearchResultCommon";

const NMISearchResultsHandler = ({
  apiResponseData,
  fuelType,
  searchDataType,
  searchResultFuelType,
  errorMessage,
  isFailedToRetrieveData,
}) => {
  const tenantId = getUserTenantId();
  if (tenantId === TENANT_1005) {
    return <EQLNmiSearchResult apiResponseData={apiResponseData}></EQLNmiSearchResult>;
  } else {
    return (
      <NMISearchResultCommon
        apiResponseData={apiResponseData}
        fuelType={fuelType}
        searchDataType={searchDataType}
        searchResultFuelType={searchResultFuelType}
        isFailedToRetrieveData={isFailedToRetrieveData}
        errorMessage={errorMessage}
      ></NMISearchResultCommon>
    );
  }
};

export default NMISearchResultsHandler;
