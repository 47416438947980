import { Auth } from "aws-amplify";
import { Button, Form } from "react-bootstrap";

const FederatedSignIn = ({ setSignInPage }) => {
  return (
    <>
      <Form.Group className="child-form">
        <Form.Label>SSO Login</Form.Label>
      </Form.Group>
      <Form.Group className="child-form mt-4">
        <Button
          className="sign-in-button"
          onClick={() => {
            Auth.federatedSignIn({ provider: process.env.REACT_APP_IDENTITY_PROVIDER });
            // setSignInPage("realms");
          }}
        >
          SIGN IN
        </Button>
      </Form.Group>
    </>
  );
};

export default FederatedSignIn;
