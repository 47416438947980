import { Auth } from "aws-amplify";
import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { log } from "../../../../utils/ALL/loggingUtils";

const ForgotPasswordNewPassword = ({ userEmail, setIsLoading, setSignInComponentToRender }) => {
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [email, setEmail] = useState(userEmail);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submitNewPassword = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    log("changing password");

    await Auth.forgotPasswordSubmit(email, code, newPassword, {})
      .then((result) => {
        log("changing password here");

        log(result);
        setSignInComponentToRender("RegularSignIn");
      })
      .catch((err) => {
        console.error(err);
        log(err.message);

        if (err.message.includes("Password does not conform to policy")) {
          handleShow();
        } else if (err.message.includes("Attempt limit exceeded,")) {
          alert("Attempt limit exceeded, please try after some time.");
        }
      });
    setIsLoading(false);
  };
  return (
    <>
      <Form.Group className="child-form" data-test="forgot-password-new-password">
        <Form.Label>Email</Form.Label>
        <Form.Control defaultValue={email} type="string" onChange={(event) => setEmail(event.target.value)} />
      </Form.Group>

      <Form.Group className="child-form">
        <Form.Label>Code</Form.Label>
        <Form.Control type="number" onChange={(event) => setCode(event.target.value)} />
      </Form.Group>

      <Form.Group className="child-form">
        <Form.Label>New Password</Form.Label>
        <Form.Control type="password" onChange={(event) => setNewPassword(event.target.value)} />
      </Form.Group>

      <Form.Group className="child-form mt-2">
        <Button type="submit" className="submit-new-password-btn" onClick={submitNewPassword}>
          Submit New Password
        </Button>
      </Form.Group>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Password Requirements</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Password minimum length <br />
          8 character(s)
          <br />
          Password requirements
          <br />
          Contains at least 1 number
          <br />
          Contains at least 1 special character
          <br />
          Contains at least 1 uppercase letter
          <br />
          Contains at least 1 lowercase letter
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ForgotPasswordNewPassword;
