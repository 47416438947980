import React, { useState } from "react";
import AUElecMasterTransaction from "./Elec/AUElecMasterTransaction";
import AUGasMasterTransaction from "./Gas/AUGasMasterTransaction";
import { getUserTenantId } from "../../../../utils/ALL/localStorageUtils";
import EqlElecMasterTransaction from "./Elec/1005/EqlElecMasterTransaction";
import { FUEL_TYPE_ELEC, FUEL_TYPE_GAS } from "../../../../utils/AU/constantAU";

const AUMasterTransactionHandler = ({
  fuelType,
  transactions,
  nmi,
  masterTransactionTxnType,
  masterTxnId,
  associatedTransactions,
}) => {
  let catsChangeReasonCode = "";
  let sordNumber = "";
  let catsRequestId = "";

  associatedTransactions.map((data) => {
    if (data.catsChangeReasonCode !== null || data.catsChangeReasonCode !== "") {
      catsChangeReasonCode = data.catsChangeReasonCode;
    }

    if (data.soNumber !== null || data.soNumber !== "") {
      sordNumber = data.soNumber;
    }

    if (data.catsChangeRequestId !== null || data.catsChangeRequestId !== "") {
      catsRequestId = data.catsChangeRequestId;
    }
  });

  if (fuelType === FUEL_TYPE_ELEC.toLowerCase()) {
    if (getUserTenantId() === "1005") {
      return (
        //TODO: move this to AUElecMasterTransaction component on the next release
        <EqlElecMasterTransaction
          transactions={transactions}
          nmi={nmi}
          masterTransactionTxnType={masterTransactionTxnType}
        ></EqlElecMasterTransaction>
      );
    } else {
      return (
        <AUElecMasterTransaction
          transactions={transactions}
          nmi={nmi}
          masterTransactionTxnType={masterTransactionTxnType}
          masterTxnId={masterTxnId}
          catsChangeReasonCode={catsChangeReasonCode}
          sordNumber={sordNumber}
        ></AUElecMasterTransaction>
      );
    }
  } else if (fuelType === FUEL_TYPE_GAS.toLowerCase()) {
    return (
      <AUGasMasterTransaction
        transactions={transactions}
        nmi={nmi}
        masterTransactionTxnType={masterTransactionTxnType}
        masterTxnId={masterTxnId}
        catsChangeReasonCode={catsChangeReasonCode}
        sordNumber={sordNumber}
      ></AUGasMasterTransaction>
    );
  } else {
    return (
      <AUElecMasterTransaction
        transactions={transactions}
        nmi={nmi}
        masterTransactionTxnType={masterTransactionTxnType}
        masterTxnId={masterTxnId}
        catsChangeReasonCode={catsChangeReasonCode}
        sordNumber={sordNumber}
      ></AUElecMasterTransaction>
    );
  }
};

export default AUMasterTransactionHandler;
