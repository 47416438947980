import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { TENANT_1001, TENANT_1002, determineTenantName } from "../../../../utils/ALL/tenantUtils";
import { DEFAULT_SORTED_3, PAGINATION_OPTIONS_1 } from "../../../../utils/bootStrapTableUtils";
import paginationFactory from "react-bootstrap-table2-paginator";
import { rowStyler } from "../../../SearchResults/NMISearchResults/Common/NMISearchCommonUtils";
import { getUserCountry, getUserTenantId } from "../../../../utils/ALL/localStorageUtils";
import {
  DATE_CREATED_AEDT,
  DATE_CREATED_AEST,
  DATE_FORMAT_3,
  convertUtcDateTimeToAedtAndFormat,
  convertUtcDateTimeToAestAndFormat,
  formatDateTimeTypeA,
  isAuSydneyInDST,
} from "../../../../utils/dateTimeUtils";
import { HTTP_STATUS_CODE_200, doPost, doPostRetryOnTimeout } from "../../../../utils/apiUtils";
import FileViewer from "../Common/FileViewer/FileViewer";
import { log, logStringifyJson } from "../../../../utils/ALL/loggingUtils";
import { SEARCH_DATA_TYPE_HISTORICAL } from "../../../Search/AU/AUSearchViewUtils";

const AUAssociatedTransactionsCommon = ({ associatedTransactions, dbName }) => {
  const TENANT_ID = getUserTenantId();

  const [currentTxnId, setCurrentTxnId] = useState("");
  const [currentFileType, setCurrentFileType] = useState("");
  const [xmlFileType, setXmlFileType] = useState("");
  const [fileContent, setFileContent] = useState("");

  const [isFetchingS3Data, setIsFetchingS3Data] = useState(false);

  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const getFile = async (row, fileType, xmlType) => {
    const TENANT_ID = getUserTenantId();
    try {
      setCurrentFileType(fileType);
      setXmlFileType(xmlType);
      setCurrentTxnId(row.transactionID);

      let request = {};
      if (TENANT_ID === TENANT_1001) {
        request = {
          transactionId: row.transactionID,
          txnIdNum: row.txnIdNum,
          fileType: fileType,
          transactionType: row.transactionType,
          fuelType: row.fuelType,
          country: getUserCountry(),
          tenantId: getUserTenantId(),
          xmlType: xmlType,
        };
      } else {
        request = {
          transactionId: row.transactionID,
          txnSortKey: row.txnSortKey,
          fileType: fileType,
          country: getUserCountry(),
          tenantId: getUserTenantId(),
          xmlType: xmlType,
        };
      }

      logStringifyJson(request);
      setIsFetchingS3Data(true);

      handleShow();

      let endpoint = "";
      if (dbName === SEARCH_DATA_TYPE_HISTORICAL) {
        endpoint = "/view/historical/transactionid";
        request.txnGroup = row.txnGroup;
        request.year = row.year;
        request.month = row.month;
        request.day = row.day;
        delete request.xmlType; //deleting xmlType type as it is not needed for historical
        delete request.transactionType; //deleting transaction type as it is not needed for historical
      } else {
        endpoint = "/view/transactionid";
      }

      logStringifyJson(request);

      const postResponse = await doPost(`${process.env.REACT_APP_BASE_API_ENDPOINT}${endpoint}`, request);
      log(postResponse);
      if (postResponse.status === HTTP_STATUS_CODE_200) {
        setFileContent(postResponse.data);
      } else {
        setFileContent("");
      }
      setIsFetchingS3Data(false);
    } catch (error) {
      setErrorMessage(`Error while getting data for transaction id ${row.transactionID}`);
      setFileContent("");
      setIsFetchingS3Data(false);
    }
  };
  const viewDataFormatter = (cell, row) => {
    /** tenants that only have ase xml files */
    if (TENANT_ID === TENANT_1001 || TENANT_ID === TENANT_1002) {
      return (
        <span className="view-data-span">
          <a
            className="view-data-link"
            onClick={() => {
              getFile(row, "xml", "aseXml");
            }}
          >
            <strong>aseXML</strong>
          </a>
        </span>
      );
    } else {
      return null;
    }
  };

  const createDateCreatedHeader = () => {
    /**Tenants that changes AU timezone */
    if (TENANT_ID === TENANT_1002) {
      if (isAuSydneyInDST()) {
        return DATE_CREATED_AEDT;
      } else {
        /**Tenants that is always AEST AU timezone */
        return DATE_CREATED_AEST;
      }
    } else {
      /**Tenants that is not define */
      return "Date Created";
    }
  };

  const formatDateCreated = (cell, row) => {
    if (TENANT_ID === TENANT_1001) {
      return formatDateTimeTypeA(cell, DATE_FORMAT_3);
    } else if (TENANT_ID === TENANT_1002) {
      if (isAuSydneyInDST()) {
        return convertUtcDateTimeToAedtAndFormat(cell, DATE_FORMAT_3);
      } else {
        return convertUtcDateTimeToAestAndFormat(cell, DATE_FORMAT_3);
      }
    } else {
      return cell;
    }
  };

  const columns = [
    {
      dataField: "transactionID",
      text: "Transaction ID",
      align: "center",
      headerAlign: "center",
    },
    {
      isDummyField: true,
      dataField: "meterPointID", //added so that the console will not throw an error
      text: "View Data",
      formatter: viewDataFormatter,
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "dateCreated",
      text: createDateCreatedHeader(),
      formatter: formatDateCreated,
      sort: true,
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "transactionType",
      text: "Transaction Type",
      align: "center",
      headerAlign: "center",
    },

    {
      dataField: "messageFilename",
      text: "Message Filename",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "sender",
      text: "Sender",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "receiver",
      text: "Receiver",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "status",
      text: "Status",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "transferDate",
      text: "Transfer Date",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "initTransactionID",
      text: "Initiating Transaction ID",
      align: "center",
      headerAlign: "center",
    },
  ];
  return (
    <>
      <Row className="associated-txn-row master-txn-wrapper-child">
        <Col>
          <div className="associated-txn-title">
            <strong>Associated Transactions</strong>
          </div>
          <BootstrapTable
            keyField="dateCreated"
            columns={columns}
            data={associatedTransactions}
            pagination={paginationFactory(PAGINATION_OPTIONS_1)}
            wrapperClasses="associated-txn-wrapper"
            rowStyle={rowStyler}
            classes="associated-transactions-table"
            defaultSorted={DEFAULT_SORTED_3}
            headerWrapperClasses={determineTenantName()}
          />
        </Col>
      </Row>
      <FileViewer
        fileType={currentFileType}
        fileContent={fileContent}
        transactionId={currentTxnId}
        showModal={show}
        isFetchingS3Data={isFetchingS3Data}
        setShowModal={setShow}
        xmlFileType={xmlFileType}
        errorMessage={errorMessage}
      ></FileViewer>
    </>
  );
};

export default AUAssociatedTransactionsCommon;
