import moment from "moment-timezone";
import { AU_TXN_GROUPS, DATE_TIME_FORMAT_2 } from "../../../../../../utils/AU/constantAU";
import { log } from "../../../../../../utils/ALL/loggingUtils";
export const eqlCreateResultTitle = (reportType) => {
  return `${reportType} ( as of ${moment.utc().add(10, "hours").format(DATE_TIME_FORMAT_2)} AEST)`;
};
const GRAPH_COLOR_MAP = new Map([
  ["CATS", { borderColor: "rgb(133, 114, 159)", backgroundColor: "rgba(133, 114, 159, 0.5)" }],
  ["CUST", { borderColor: "rgb(192, 177, 208)", backgroundColor: "rgba(192, 177, 208, 0.5)" }],
  ["MDMT", { borderColor: "rgb(214, 166, 214)", backgroundColor: "rgba(214, 166, 214, 0.5)" }],
  ["MTRD", { borderColor: "rgb(223, 187, 197)", backgroundColor: "rgba(223, 187, 197, 0.5)" }],
  ["NMID", { borderColor: "rgb(223, 209, 199)", backgroundColor: "rgba(223, 209, 199, 0.5)" }],
  ["NPNX", { borderColor: "rgb(172, 199, 208)", backgroundColor: "rgba(172, 199, 208, 0.5)" }],
  ["OWNX", { borderColor: "rgb(180, 195, 218)", backgroundColor: "rgba(180, 195, 218, 0.5)" }],
  ["SITE", { borderColor: "rgb(240, 229, 243)", backgroundColor: "rgba(240, 229, 243, 0.5)" }],
  ["SORD", { borderColor: "rgb(94, 154, 164)", backgroundColor: "rgba(94, 154, 164, 0.5)" }],
]);
const LABELS = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];

export const eqlCreateTimeRange = async () => {
  // event.preventDefault();
  const timeStamp = moment;
  // //get utc time,
  // const currentUTCtime = timeStamp.utc();
  //convert to fixed aest (+10)
  let currentAESTTime = timeStamp.utc().add(10, "hours");

  log("current aest time " + currentAESTTime.toISOString());

  let hoursToDeduct = currentAESTTime.hour();

  let deductedAESTTime = currentAESTTime.subtract(hoursToDeduct, "hours");

  let startDateUtc = deductedAESTTime.subtract(10, "hours");

  let startDateUtcFormatted = startDateUtc.set("seconds", 0).set("minutes", 0).set("milliseconds", 0).toISOString();

  let endDateUtc = startDateUtc.add(1, "days");
  let endDateUtcFormatted = endDateUtc.set("seconds", 0).set("minutes", 0).set("milliseconds", 0).toISOString();

  let dateTimeRange = {
    dateTimeStart: startDateUtcFormatted,
    dateTimeEnd: endDateUtcFormatted,
  };

  return dateTimeRange;
};

let outgoingTxnGroupTotalCountMap = new Map([]);
let incomingTxnGroupTotalCountMap = new Map([]);

export const eqlCreateGraphData = async (apiData, dateTimeStart) => {
  log("Creating graph data");

  let dateTimeArr = [];
  for (let index = 0; index < 24; index++) {
    let newHourValue = moment(dateTimeStart).add(index, "hour");
    dateTimeArr = [...dateTimeArr, newHourValue.toISOString()];
  }

  const hourUtcTimeMap = new Map([]);
  LABELS.map((label, index) => {
    hourUtcTimeMap.set(label, dateTimeArr[index]);
  });

  const apiResponseMap = new Map([]);
  apiData.map((data) => {
    apiResponseMap.set(data.txnType, data.summary);
  });

  const { incomingSubtitle, outgoingSubtitle } = await createGraphSubtitle(apiResponseMap);
  const { incomingConsolidatedData, outgoingConsolidatedData } = await createConsolidatedData(
    hourUtcTimeMap,
    apiResponseMap
  );

  let dataTobeShownIncoming = createDataSets(incomingConsolidatedData);

  // used for setting 24 length array when a data was not present
  dataTobeShownIncoming.datasets.map((currentData, index) => {
    if (currentData.data) {
    } else {
      currentData.data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    }
  });

  let dataTobeShownOutgoing = createDataSets(outgoingConsolidatedData);

  //used for setting 24 length array when a data was not present
  dataTobeShownOutgoing.datasets.map((currentData, index) => {
    if (currentData.data) {
    } else {
      currentData.data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    }
  });

  return { incomingSubtitle, outgoingSubtitle, dataTobeShownIncoming, dataTobeShownOutgoing };
};

const createConsolidatedData = async (hourUtcTimeMap, apiResponseMap) => {
  log("Creating consolidated data");

  let incomingConsolidatedData = new Map([]);
  let outgoingConsolidatedData = new Map([]);
  AU_TXN_GROUPS.map((txnGroup) => {
    for (const [hour, utcTime] of hourUtcTimeMap) {
      // log(`Looking up txn group ${txnGroup} with ${hour} that is ${utcTime} UTC Time.`);

      if (apiResponseMap.get(txnGroup)) {
        // log(`${txnGroup} exist on the api response`);
        if ("incoming" in apiResponseMap.get(txnGroup)) {
          // log("Incoming data exist");
          let incomingMap = new Map([]);
          apiResponseMap.get(txnGroup).incoming.map((incomingData) => {
            incomingMap.set(incomingData.dateTime, incomingData.count);
          });
          // log(incomingMap);

          let currentCount = 0;
          if (incomingMap.get(utcTime)) {
            currentCount = incomingMap.get(utcTime);
          } else {
            currentCount = 0;
          }
          if (incomingConsolidatedData.get(txnGroup)) {
            // log("data from incomingConsolidatedData");
            incomingConsolidatedData.set(txnGroup, [...incomingConsolidatedData.get(txnGroup), currentCount]);
          } else {
            incomingConsolidatedData.set(txnGroup, [currentCount]);
            // log("no data from consolidatedData");
          }
        } else {
          // log("Incoming data does not exist");
        }

        if ("outgoing" in apiResponseMap.get(txnGroup)) {
          // log("Outgoing data exist");
          let outgoingMap = new Map([]);
          apiResponseMap.get(txnGroup).outgoing.map((outgoingData) => {
            outgoingMap.set(outgoingData.dateTime, outgoingData.count);
          });
          // log(outgoingMap);

          let currentCount = 0;
          if (outgoingMap.get(utcTime)) {
            currentCount = outgoingMap.get(utcTime);
          } else {
            currentCount = 0;
          }
          if (outgoingConsolidatedData.get(txnGroup)) {
            // log("data from incomingConsolidatedData");
            outgoingConsolidatedData.set(txnGroup, [...outgoingConsolidatedData.get(txnGroup), currentCount]);
          } else {
            outgoingConsolidatedData.set(txnGroup, [currentCount]);
            // log("no data from consolidatedData");
          }
        } else {
          // log("Outgoing data does not exist");
        }
      } else {
        // log(`${txnGroup} does not exist on the api response`);
      }
    }
    // log(incomingConsolidatedData);
    // log(outgoingConsolidatedData);
  });
  return { incomingConsolidatedData, outgoingConsolidatedData };
};

const createGraphSubtitle = async (apiResponseMap) => {
  log("Creating graph subtitle");

  log("Summarizing transaction count per transaction group");

  AU_TXN_GROUPS.map((txnGroup) => {
    if (apiResponseMap.get(txnGroup)) {
      //process for outgoing
      if (apiResponseMap.get(txnGroup).outgoing) {
        //loop to each objects
        let totalCount = 0;
        apiResponseMap.get(txnGroup).outgoing.map((outgoingData) => {
          totalCount = totalCount + outgoingData.count;
        });
        // log("Total count is " + totalCount);
        outgoingTxnGroupTotalCountMap.set(txnGroup, totalCount);
      } else {
        outgoingTxnGroupTotalCountMap.set(txnGroup, 0);
      }
      //process for incoming
      if (apiResponseMap.get(txnGroup).incoming) {
        //loop to each objects
        let totalCount = 0;
        apiResponseMap.get(txnGroup).incoming.map((incomingData) => {
          totalCount = totalCount + incomingData.count;
        });
        // log("Total count is " + totalCount);
        incomingTxnGroupTotalCountMap.set(txnGroup, totalCount);
      } else {
        incomingTxnGroupTotalCountMap.set(txnGroup, 0);
      }
    } else {
      outgoingTxnGroupTotalCountMap.set(txnGroup, 0);
      incomingTxnGroupTotalCountMap.set(txnGroup, 0);
    }
  });

  // log(outgoingTxnGroupTotalCountMap);
  // log(incomingTxnGroupTotalCountMap);

  log("Creating graph subtitle to be shown");

  const incomingSubtitle = `Total Processed Today: CATS - ${incomingTxnGroupTotalCountMap.get(
    "CATS"
  )} | CUST - ${incomingTxnGroupTotalCountMap.get("CUST")} | MDMT - ${incomingTxnGroupTotalCountMap.get(
    "MDMT"
  )} | MTRD -  ${incomingTxnGroupTotalCountMap.get("MTRD")} | NMID - ${incomingTxnGroupTotalCountMap.get(
    "NMID"
  )} | NPNX - ${incomingTxnGroupTotalCountMap.get("NPNX")} | OWNX - ${incomingTxnGroupTotalCountMap.get(
    "OWNX"
  )} | SITE - ${incomingTxnGroupTotalCountMap.get("SITE")} | SORD - ${incomingTxnGroupTotalCountMap.get("SORD")}`;

  const outgoingSubtitle = `Total Processed Today: CATS - ${outgoingTxnGroupTotalCountMap.get(
    "CATS"
  )} | CUST - ${outgoingTxnGroupTotalCountMap.get("CUST")} | MDMT - ${outgoingTxnGroupTotalCountMap.get(
    "MDMT"
  )} | MTRD -  ${outgoingTxnGroupTotalCountMap.get("MTRD")} | NMID - ${outgoingTxnGroupTotalCountMap.get(
    "NMID"
  )} | NPNX - ${outgoingTxnGroupTotalCountMap.get("NPNX")} | OWNX - ${outgoingTxnGroupTotalCountMap.get(
    "OWNX"
  )} | SITE - ${outgoingTxnGroupTotalCountMap.get("SITE")} | SORD - ${outgoingTxnGroupTotalCountMap.get("SORD")}`;

  // log("incomingSubtitle: " + incomingSubtitle);
  // log("outgoingSubtitle: " + outgoingSubtitle);

  return { incomingSubtitle, outgoingSubtitle };
};

const createDataSets = (consolidatedData) => {
  log("creating data sets");

  let dataSets = {
    labels: LABELS,
    datasets: [],
  };

  AU_TXN_GROUPS.map((data) => {
    let dataToPush = {
      fill: true,
      label: data,
      data: consolidatedData.get(data),
      borderColor: GRAPH_COLOR_MAP.get(data).borderColor,
      backgroundColor: GRAPH_COLOR_MAP.get(data).backgroundColor,
      tension: 0.3,
      hidden: false,
    };

    dataSets.datasets.push(dataToPush);
  });

  // log("created dataSets -> " + JSON.stringify(dataSets));

  return dataSets;
};
