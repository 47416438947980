import { getUserTenantId } from "../../../../utils/ALL/localStorageUtils";
import EQLSearchNav from "./1005/EQLSearchNav";
import AUCommonSearchNav from "./Common/AUCommonSearchNav";

const AUSearchNavBarHandler = () => {
   const tenantId = getUserTenantId();
   if (tenantId === "1005") {
     return <EQLSearchNav></EQLSearchNav>;
   } else {
     return <AUCommonSearchNav></AUCommonSearchNav>;
   }
//   return <AUCommonSearchNav></AUCommonSearchNav>;

};

export default AUSearchNavBarHandler;
