import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import "./Footer.css";
import { portalVersion } from "../../../utils/version";
const Footer = () => {
  return (
    <Navbar fixed="bottom" className="footer-custom" data-test="footer-navbar">
      <Nav>{portalVersion()}</Nav>
      <Nav className="ms-auto">© {new Date().getFullYear()} Accenture. All Rights Reserved.</Nav>
    </Navbar>
  );
};

export default Footer;
