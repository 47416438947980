import { Auth } from "aws-amplify";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { log } from "../../../../utils/ALL/loggingUtils";
import PasswordRequirement from "../PasswordRequirement/PasswordRequirement";

const ChangePasswordFirstLogin = ({
  currentEmail,
  currentPassword,
  currentUser,
  setIsLoading,
  setSignInComponentToRender,
}) => {
  const [newPassword, setNewPassword] = useState("");
  const [email, setEmail] = useState(currentEmail);
  const [password, setPassword] = useState(currentPassword);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const submitNewPasswordOnFirstLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await Auth.completeNewPassword(currentUser, newPassword, {})
      .then((data) => {
        log(data);
        if (data.challengeName) {
          log("challenge required");
          switch (data.challengeName) {
            case "MFA_SETUP":
              log("need to setup mfa");
              setSignInComponentToRender("SetUpMFADevice");

              break;
          }
        } else {
          log("no challenge detected");
        }
      })
      .catch((err) => {
        console.error(err);
        if (err.message.includes("Password does not conform to policy")) {
          handleShow();
        }
      });
    setIsLoading(false);
  };
  return (
    <>
      <Form.Group className="child-form" data-test="change-password-first-login-component-form">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          value={email}
        />
      </Form.Group>

      <Form.Group className="child-form">
        <Form.Label>Password</Form.Label>
        <Form.Control value={password} type="password" onChange={(event) => setPassword(event.target.value)} />
      </Form.Group>

      <Form.Group className="child-form">
        <Form.Label>New Password</Form.Label>
        <Form.Control type="password" onChange={(event) => setNewPassword(event.target.value)} />
      </Form.Group>

      <Form.Group className="child-form mt-2">
        <Button type="submit" className="submit-new-password-btn" onClick={submitNewPasswordOnFirstLogin}>
          Submit New Password
        </Button>
      </Form.Group>
      <PasswordRequirement show={show} handleClose={handleClose}></PasswordRequirement>
    </>
  );
};

export default ChangePasswordFirstLogin;
