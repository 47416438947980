import { Accordion, Button, Col, Form, Row, Spinner, Tab } from "react-bootstrap";
import { TENANT_1001, determineTenantName } from "../../../utils/ALL/tenantUtils";
import { useState } from "react";
import {
  HTTP_STATUS_CODE_200,
  HTTP_STATUS_CODE_400,
  HTTP_STATUS_CODE_401,
  HTTP_STATUS_CODE_504,
  doPost,
} from "../../../utils/apiUtils";
import { getUserCountry, getUserTenantId } from "../../../utils/ALL/localStorageUtils";
import { Auth } from "aws-amplify";
import NMISearchResultsHandler from "../../SearchResults/NMISearchResults/NMISearchResultsHandler";
import FuelTypeSelection from "./FuelTypeSelection/FuelTypeSelection";
import HistoricalSearch from "./Selection/HistoricalSearch/HistoricalSearch";
import {
  SEARCH_DATA_TYPE_ACTIVE,
  SEARCH_TYPE_HISTORICAL_NMI,
  SEARCH_TYPE_NMI,
  SEARCH_TYPE_TRANSACTION_ID,
} from "./AUSearchViewUtils";
import { log } from "../../../utils/ALL/loggingUtils";
import SelectionSeparator from "../../Common/ReusableComponents/Separator/SelectionSeparator";
import { FUEL_TYPE_ELEC, FUEL_TYPE_GAS } from "../../../utils/AU/constantAU";

const AUSearchView = () => {
  const TENANT_ID = getUserTenantId();

  const [nmi, setNmi] = useState("");
  const [nmiSearchResult, setNmiSearchResult] = useState([]);

  const [transactionId, setTransactionID] = useState("");
  const [txnIdSearchResult, setTxnIdSearchResult] = useState([]);
  const [historicalNmiSearchResult, setHistoricalNmiSearchResult] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isFailedToRetrieveData, setIsFailedToRetrieveData] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [searchType, setSearchType] = useState("");

  const [fuelType, setFuelType] = useState("elec");
  const [searchResultFuelType, setSearchResultFuelType] = useState("elec");
  const [isToIncludeHistoricalData, setIsToIncludeHistoricalData] = useState(false);

  const [masterTransactionType, setMasterTransactionType] = useState("");
  const [searchDataType, setSearchDataType] = useState("active");
  /**
   *Use this function to dynamically render tenant specific search fields
   *
   * @param {*} lastEventKey - the value of lastEventKey of the <Accordion.Item>
   */
  const showAdditionalSearchFields = (lastEventKey) => {
    if (TENANT_ID === TENANT_1001) {
      return (
        <HistoricalSearch
          lastEventKey={lastEventKey}
          fuelType={fuelType}
          setSearchDataType={setSearchDataType}
          masterTransactionType={masterTransactionType}
          setMasterTransactionType={setMasterTransactionType}
          setSearchType={setSearchType}
          setIsLoading={setIsLoading}
          setHistoricalNmiSearchResult={setHistoricalNmiSearchResult}
          setSearchResultFuelType={setSearchResultFuelType}
          setErrorMessage={setErrorMessage}
          setIsFailedToRetrieveData={setIsFailedToRetrieveData}
        ></HistoricalSearch>
      );
    } else {
      return null;
    }
  };
  const handleNmiSearch = async (event) => {
    event.preventDefault();
    setSearchDataType(SEARCH_DATA_TYPE_ACTIVE);
    setSearchResultFuelType(fuelType);

    let params = {};
    if (TENANT_ID === TENANT_1001) {
      params = {
        meterPointId: nmi,
        country: getUserCountry(),
        tenantId: getUserTenantId(),
        fuelType,
        includeHistoricalData: isToIncludeHistoricalData,
      };
    } else {
      params = {
        meterPointId: nmi,
        country: getUserCountry(),
        tenantId: getUserTenantId(),
      };
    }

    setNmiSearchResult([]);
    setSearchType("nmi");
    log(`Searching for NMI: ${nmi}`);

    try {
      setIsLoading(true);
      setIsFailedToRetrieveData(false);
      const postResponse = await doPost(`${process.env.REACT_APP_BASE_API_ENDPOINT}/search/meterpointid`, params);

      if (postResponse.status === HTTP_STATUS_CODE_400) {
        log("Got 400 status code from API");
        setErrorMessage("Error while processing request");
        setIsFailedToRetrieveData(true);
      } else if (postResponse.status === HTTP_STATUS_CODE_401) {
        alert("You are no longer authorized. Please LogIn again");
        return await Auth.signOut();
      } else if (postResponse.status === HTTP_STATUS_CODE_200) {
        setNmiSearchResult(postResponse.data);
        setIsFailedToRetrieveData(false);
      } else if (postResponse.status === HTTP_STATUS_CODE_504) {
        setErrorMessage("Taking too long to process the request");
        setNmiSearchResult([]);
        setIsFailedToRetrieveData(true);
      } else {
        log("Unhandled http status code");

        setNmiSearchResult([]);
        setIsFailedToRetrieveData(true);
      }
      setIsLoading(false);
    } catch (error) {
      log("error in getting NMI details");
      log(error);
      setErrorMessage("Error while retrieving data");
      setIsFailedToRetrieveData(true);
      setIsLoading(false);
    }
  };

  const handleTxnIdSearch = async (event) => {
    event.preventDefault();
    setSearchDataType(SEARCH_DATA_TYPE_ACTIVE);
    setSearchResultFuelType(fuelType);
    setTxnIdSearchResult([]);
    setSearchType("transaction-id");
    log(`Searching for TransactionID: ${transactionId}`);

    try {
      setIsLoading(true);
      setIsFailedToRetrieveData(false);

      let tenantId = getUserTenantId();

      let params = {};
      if (tenantId === "1001") {
        params = {
          transactionId: transactionId,
          country: getUserCountry(),
          tenantId: getUserTenantId(),
          fuelType,
        };
      } else {
        params = {
          transactionId: transactionId,
          country: getUserCountry(),
          tenantId: getUserTenantId(),
        };
      }

      const postResponse = await doPost(`${process.env.REACT_APP_BASE_API_ENDPOINT}/search/transactionid`, params);

      if (postResponse.status === 400) {
        log(postResponse.data);
        setIsFailedToRetrieveData(true);
      } else if (postResponse.status === 401) {
        alert("You are no longer authorized. Please LogIn again");
        return await Auth.signOut();
      } else if (postResponse.status === 200) {
        if (postResponse.data.length > 0) {
          setTxnIdSearchResult(postResponse.data);
        } else {
          setIsFailedToRetrieveData(true);
        }
      }

      setIsLoading(false);
    } catch (error) {
      log("error in searching transaction id");
      log(error);
      setIsLoading(false);
    }
  };

  const determineSearchResultView = () => {
    if (searchType === SEARCH_TYPE_NMI) {
      return (
        <NMISearchResultsHandler
          searchDataType={searchDataType}
          apiResponseData={nmiSearchResult}
          fuelType={searchResultFuelType}
          isToIncludeHistoricalData={isToIncludeHistoricalData}
          searchResultFuelType={searchResultFuelType}
          isFailedToRetrieveData={isFailedToRetrieveData}
          errorMessage={errorMessage}
        ></NMISearchResultsHandler>
      );
    } else if (searchType === SEARCH_TYPE_TRANSACTION_ID) {
      /** NMI search result and transaction is the same, reusing the  NMISearchHandler component*/
      return (
        <NMISearchResultsHandler
          searchDataType={searchDataType}
          apiResponseData={txnIdSearchResult}
          fuelType={fuelType}
          searchResultFuelType={searchResultFuelType}
        ></NMISearchResultsHandler>
      );
    } else if (searchType === SEARCH_TYPE_HISTORICAL_NMI) {
      /** NMI search result and historical nmi search has the same result, reusing the  NMISearchHandler component*/
      return (
        <NMISearchResultsHandler
          searchDataType={searchDataType}
          apiResponseData={historicalNmiSearchResult}
          fuelType={fuelType}
          searchResultFuelType={searchResultFuelType}
          isFailedToRetrieveData={isFailedToRetrieveData}
          errorMessage={errorMessage}
        ></NMISearchResultsHandler>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="search-form-wrapper">
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row className="search-row mt-3" style={{ marginBottom: "60px" }}>
          <Col sm={3} className="search-input-column">
            <div className="search-input-title" id={determineTenantName()}>
              Search Input
            </div>
            <FuelTypeSelection
              setFuelType={setFuelType}
              fuelType={fuelType}
              setMasterTransactionType={setMasterTransactionType}
            ></FuelTypeSelection>

            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header className="items-search-header">
                  {fuelType === FUEL_TYPE_GAS.toLowerCase() ? "MIRN" : "NMI"}
                </Accordion.Header>
                <Accordion.Body>
                  <Form>
                    <Form.Group>
                      <Form.Label>{fuelType === FUEL_TYPE_GAS.toLowerCase() ? "MIRN" : "NMI"}</Form.Label>
                      <Form.Control
                        name="meterpointId"
                        type="text"
                        onChange={(e) => setNmi(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group className="search-button-div">
                      <Button type="submit" className="mt-2 search-btn" onClick={handleNmiSearch}>
                        Search
                      </Button>
                    </Form.Group>
                  </Form>
                </Accordion.Body>
              </Accordion.Item>
              <SelectionSeparator></SelectionSeparator>
              <Accordion.Item eventKey="1">
                <Accordion.Header className="items-search-header">Transaction ID</Accordion.Header>
                <Accordion.Body>
                  <Form>
                    <Form.Group>
                      <Form.Label>Transaction ID</Form.Label>
                      <Form.Control
                        name="transactionId"
                        type="text"
                        onChange={(e) => setTransactionID(e.target.value)}
                      ></Form.Control>
                      <Form.Group className="search-button-div">
                        <Button type="submit" className="mt-2 search-btn" onClick={handleTxnIdSearch}>
                          Search
                        </Button>
                      </Form.Group>
                    </Form.Group>
                  </Form>
                </Accordion.Body>
              </Accordion.Item>
              <SelectionSeparator></SelectionSeparator>
              {showAdditionalSearchFields(1)}
            </Accordion>
          </Col>
          <Col sm={9} className="search-result-column">
            <Tab.Content className="search-result-tab-content">
              <div className="search-results-title" id={determineTenantName()}>
                Search Results
              </div>
              <div className="empty-div">
                {isLoading ? (
                  <div className="spinner-div-searching">
                    <Spinner animation="border" variant="danger" className="spinner-searching" />
                  </div>
                ) : null}

                {determineSearchResultView()}
              </div>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};

export default AUSearchView;
