import { getUserCountry } from "../../../utils/ALL/localStorageUtils";
import NZAdminNavBar from "./NZ/NZAdminNavBar";

const AdminNavigationBar = ({ active, handleOnCLickDropDownMenu }) => {
  const userCountry = getUserCountry().toUpperCase();
  if (userCountry === "NZ") {
    return <NZAdminNavBar active={active} handleOnCLickDropDownMenu={handleOnCLickDropDownMenu}></NZAdminNavBar>;
  } else {
    return null;
  }
};

export default AdminNavigationBar;
