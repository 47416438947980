import { Auth } from "aws-amplify";
import QRCode from "qrcode.react";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { log } from "../../../../utils/ALL/loggingUtils";

const SetUpMFADevice = ({ currentUser, setIsLoading }) => {
  const [str, setStr] = useState("");
  const [isShowQrCode, setIsShowQrCode] = useState(false);
  const [mfaCode, setMfaCode] = useState("");

  useEffect(() => {
    log("use effect setupmfa");
  }, []);

  const setUpMFA = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await Auth.setupTOTP(currentUser)
      .then((code) => {
        // You can directly display the `code` to the user or convert it to a QR code to be scanned.
        // E.g., use following code sample to render a QR code with `qrcode.react` component:
        //      import QRCode from 'qrcode.react';
        let str1 = "";
        if (process.env.REACT_APP_ENVI === "dev") {
          str1 = "MDHPortalDEV";
        } else if (process.env.REACT_APP_ENVI === "test") {
          str1 = "MDHPortalTEST";
        } else if (process.env.REACT_APP_ENVI === "sit") {
          str1 = "MDHPortalSIT";
        } else if (process.env.REACT_APP_ENVI === "prod") {
          str1 = "MDHPortal";
        }

        setStr(`otpauth://totp/${str1}:` + currentUser.username + "?secret=" + code + "&issuer=" + str1);
        setIsShowQrCode(true);
      })
      .catch((err) => {
        log(err);
      });
    setIsLoading(false);
  };

  const verifyOTP = async (e) => {
    e.preventDefault();
    log(str);

    await Auth.verifyTotpToken(currentUser, mfaCode)
      .then((data) => {
        // don't forget to set TOTP as the preferred MFA method
        log(data);

        Auth.setPreferredMFA(currentUser, "TOTP");
      })
      .catch((e) => {
        console.error(e);

        if (e.message === "Code mismatch") {
          alert("Code mismatched. Input the correct OTP");
        }
      });
  };

  return (
    <>
      <Form.Group className="child-form" data-test="set-up-mfa-device-form">
        <Form.Label>
          Prepare your MFA device. <br />
          Click "Show QR Code" to generate a QE Code.
        </Form.Label>
        <Button onClick={setUpMFA} className="show-qr-code-btn mb-2">
          Show QR Code
        </Button>
      </Form.Group>

      <Form.Group className="child-form qr-code-holder">
        {isShowQrCode ? (
          <>
            <QRCode value={str} />
            <p>
              Scan the QR code then input the <br />
              OTP (One-time PassWord) below
            </p>
          </>
        ) : null}
      </Form.Group>

      <Form.Group className="child-form">
        <Form.Label>MFA Code</Form.Label>
        <Form.Control
          type="number"
          onChange={(event) => {
            setMfaCode(event.target.value);
          }}
        />
        <Button type="submit" onClick={verifyOTP} className="submit-mfa-button mt-2">
          Submit MFA code
        </Button>
      </Form.Group>
    </>
  );
};

export default SetUpMFADevice;
