import { getUserTenantId } from "../../../../utils/ALL/localStorageUtils";
import EqlAssociatedTransactions from "./1005/EqlAssociatedTransactions";
import AUAssociatedTransactionsCommon from "./AUAssociatedTransactionsCommon";

const AUAssociatedTransactionsHandler = ({ associatedTransactions, dbName }) => {
  const tenantId = getUserTenantId();
  if (tenantId === "1005") {
    return <EqlAssociatedTransactions associatedTransactions={associatedTransactions}></EqlAssociatedTransactions>;
  } else {
    return (
      <AUAssociatedTransactionsCommon
        associatedTransactions={associatedTransactions}
        dbName={dbName}
      ></AUAssociatedTransactionsCommon>
    );
  }
};

export default AUAssociatedTransactionsHandler;
