import { Auth } from "aws-amplify";
import React, { useState } from "react";
import { Image, Nav, Navbar, Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Header.css";
import { determineTenantName } from "../../../utils/ALL/tenantUtils";
import SearchNavigationBarHandler from "../../NavigationBar/SearchNavigationBar/SearchNavigationBarHandler";
import AdminNavigationBar from "../../NavigationBar/AdminNavigationBar/AdminNavigationBar";
import ReportsNavigationBar from "../../NavigationBar/Reports/ReportsNavigationBar";
const Header = ({ accessGroups, isUserAllowedToViewReportsTab, isUserAllowedToViewSearchTab }) => {
  const [active, setActive] = useState(false);
  const navigate = useNavigate();

  const logout = async (event) => {
    await Auth.signOut();
    navigate("/");
  };
  const handleOnCLickDropDownMenu = () => {
    setActive(!active);
  };

  const portalEnv = () => {
    let txt = "";
    switch (process.env.REACT_APP_ENVI.toUpperCase()) {
      case "DEV":
        txt = "Development Portal";
        break;
      case "TEST":
        txt = "Internal Test Portal";
        break;
      case "SIT":
        txt = "External SIT Portal";
        break;
      case "PROD":
        txt = "Production Portal";
        break;
    }

    return <Badge className="env-badge">{txt}</Badge>;
  };

  const getTenantLogo = () => {
    const tenantName = determineTenantName();

    if (tenantName === "octo") {
      return <Image src="/images/OctoEnergy.jpg" width={100} height={50} alt="85x90" />;
    } else if (tenantName === "eql") {
      return <Image src="/images/EnergyQueensland.png" width={50} height={50} alt="50x50" />;
    } else if (tenantName === "origin") {
      return <Image src="/images/OriginEnergy.png" width={50} height={50} alt="50x50" />;
    } else if (tenantName === "nectr") {
      return <Image src="/images/nectr/NectrEnergy.png" width={"auto"} height={50} alt="50x50" />;
    } else {
      return <Image src="/images/tenantSample.png" width={100} height={50} alt="85x90" />;
    }
  };

  return (
    <header>
      <Navbar bg="dark" variant="dark">
        <Nav className="mr-auto px-1 nav-acn-logo">
          <Image
            className="acn-navbar-logo"
            src="/images/accenture/acc_logo_white_purple.png"
            width={100}
            height={25}
            alt="85x90"
          />
        </Nav>
        <Nav className="mr-auto px-1 vertical-line-holder">
          <div className="vertical-line-navbar"></div>
        </Nav>
        <Navbar.Brand className="px-1">Market Data Hub</Navbar.Brand>
        <Nav className="mr-auto px-1">{portalEnv()}</Nav>

        <Nav className="ms-auto header-logo-right">{getTenantLogo()}</Nav>
      </Navbar>
      <Navbar className="navbar-secondary">
        <Nav defaultActiveKey="/search">
          {isUserAllowedToViewSearchTab() ? <SearchNavigationBarHandler /> : null}
          <AdminNavigationBar
            active={active}
            handleOnCLickDropDownMenu={handleOnCLickDropDownMenu}
          ></AdminNavigationBar>
          {isUserAllowedToViewReportsTab() ? (
            <ReportsNavigationBar accessGroups={accessGroups}></ReportsNavigationBar>
          ) : null}
        </Nav>
        <Nav className="ms-auto nav-logout">
          <a className="header-active logout-btn" onClick={() => logout()}>
            Logout
          </a>
        </Nav>
      </Navbar>
    </header>
  );
};

export default Header;
