import React from "react";
import { getUserTenantId } from "../../../../utils/ALL/localStorageUtils";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { FUEL_TYPES } from "../../../../utils/AU/constantAU";
import "./FuelTypeSelection.css";
const FuelTypeSelection = ({ fuelType, setFuelType, setMasterTransactionType }) => {
  const tenantId = getUserTenantId();

  if (tenantId === "1001") {
    return (
      <div className="fuel-type-selection-wrapper">
        <div>Fuel</div>
        <ButtonGroup className="fuel-type-selection-btn-groups">
          {FUEL_TYPES.map((data, idx) => {
            return (
              <ToggleButton
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                name="radio"
                value={data.value}
                checked={fuelType === data.value}
                onChange={(e) => {
                  setFuelType(e.currentTarget.value);
                  setMasterTransactionType("");
                }}
                className={`btn-btn-group-num-${idx}`}
              >
                {data.name}
              </ToggleButton>
            );
          })}
        </ButtonGroup>
      </div>
    );
  } else {
    return null;
  }
};

export default FuelTypeSelection;
