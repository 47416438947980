import { Auth } from "aws-amplify";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import "./RegularSignIn.css";
import { log } from "../../../../utils/ALL/loggingUtils";
const RegularSignIn = ({
  setIsLoading,
  setCurrentUser,
  setSignInComponentToRender,
  setCurrentEmail,
  setCurrentPassword,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const signIn = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    setCurrentEmail(email);
    setCurrentPassword(password);
    await Auth.signIn({ username: email, password: password })
      .then((result) => {
        log("amplify signin");
        setCurrentUser(result);
        if (result.challengeName) {
          log("challenge required");
          switch (result.challengeName) {
            case "NEW_PASSWORD_REQUIRED":
              alert("Please change your password.");
              setSignInComponentToRender("FirstLoginChangePassword");

              break;
            case "MFA_SETUP":
              log("need to setup mfa");
              setSignInComponentToRender("SetUpMFADevice");

              break;

            case "SOFTWARE_TOKEN_MFA":
              log("MFA code is required");

              setSignInComponentToRender("MFACodeRequired");
              break;
            default:
              console.error("Challenge is not handled");
              break;
          }
        } else {
          log("no challenge detected");
        }
      })
      .catch((err) => {
        console.error(err);
      });
    setIsLoading(false);
  };

  return (
    <>
      <Form.Group className="child-form" data-test="regular-sign-in-form">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        />
      </Form.Group>

      <Form.Group className="child-form">
        <Form.Label>Password</Form.Label>
        <Form.Control type="password" onChange={(event) => setPassword(event.target.value)} />
      </Form.Group>

      <Form.Group className="child-form mt-4">
        <Button type="submit" className="sign-in-button" onClick={signIn}>
          LOGIN
        </Button>
      </Form.Group>

      <Form.Group className="child-form mt-2">
        <div className="forgot-password-line">
          <span
            className="span-forgot-pw"
            onClick={() => {
              setSignInComponentToRender("UserForgotPassword");
            }}
          >
            Forgot Password?
          </span>
        </div>
      </Form.Group>
    </>
  );
};

export default RegularSignIn;
