import { Auth } from "aws-amplify";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { log } from "../../../../utils/ALL/loggingUtils";
const ForgotPassword = ({ backToSignIn, setIsLoading, setSignInComponentToRender, setCurrentEmail }) => {
  const [email, setEmail] = useState("");

  const handleForgotPassword = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setCurrentEmail(email);
    log("Send email code clicked!");
    await Auth.forgotPassword(email)
      .then((data) => {
        log(data);
        setSignInComponentToRender("ChangePasswordAfterCodeSent");
      })
      .catch((err) => {
        log(err);
        if (err.message.includes("CodeDeliveryFailureException:")) {
          return alert("Problem on sending code to your email.");
        }
      });
    setIsLoading(false);
  };

  return (
    <>
      <Form.Group className="child-form" data-test="forgot-password-form">
        <Form.Label>Email</Form.Label>
        <Form.Control type="string" onChange={(event) => setEmail(event.target.value)} />
      </Form.Group>
      <Form.Group className="child-form mt-2">
        <Button type="submit" className="sign-in-button" onClick={handleForgotPassword}>
          Send Code
        </Button>
      </Form.Group>
    </>
  );
};

export default ForgotPassword;
