import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import { formatDateCreatedUTC, sortByDateAscending } from "./NZMasterTransactionUtils";

const NZMasterTransaction = ({ transactions, icp, masterTransactionTxnType }) => {
  const sortedTransaction = transactions.sort(sortByDateAscending);
  const firstTxn = sortedTransaction[0];

  const sourceFormatter = () => {
    if (masterTransactionTxnType.toUpperCase() === "MTRD" || masterTransactionTxnType.toUpperCase() === "SWITCH") {
      return firstTxn.sender.toUpperCase();
    }
  };

  const destinationFormatter = () => {
    if (masterTransactionTxnType.toUpperCase() === "MTRD") {
      return "Octopus Energy";
    } else if (masterTransactionTxnType.toUpperCase() === "SWITCH") {
      if (firstTxn.receiver.toUpperCase() === "OCTO") {
        return "Octopus Energy";
      } else {
        return firstTxn.receiver.toUpperCase();
      }
    }
  };
  return (
    <Row className="master-transaction-row master-txn-wrapper-child" data-test="master-transaction-component">
      <Col>
        <Table size="sm" borderless>
          <thead>
            <tr>
              <td>
                <strong>Master Transaction</strong>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Date Created:</td>
              <td>{formatDateCreatedUTC(firstTxn.date_created)} (UTC)</td>
              <td>Source:</td>
              <td>{sourceFormatter()}</td>
            </tr>
            <tr>
              <td>Master Transaction ID:</td>
              <td>{firstTxn.master_txn_id}</td>
              <td>Destination</td>
              <td>{destinationFormatter()}</td>
            </tr>
            <tr>
              <td>Customer Reference Number:</td>
              <td>{icp}</td>
            </tr>
            <tr>
              <td>Transaction Type</td>
              <td>{masterTransactionTxnType}</td>
            </tr>
            <tr>
              <td>Role</td>
              <td>Trader</td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default NZMasterTransaction;
