import { NavLink } from "react-router-dom";

const NZCommonSearchNav = ({ activeNav, setActiveNav }) => {
  return (
    <NavLink
      className={`header-active ${activeNav == "search-nav" && "active-nav"}`}
      href="/search"
      exact="true"
      to="/search"
    >
      Search
    </NavLink>
  );
};

export default NZCommonSearchNav;
