import React from "react";
import { Button, Modal } from "react-bootstrap";
import "./PasswordRequirement.css";
const PasswordRequirement = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} contentClassName="pw-requirement-dialog">
      <Modal.Header>
        <Modal.Title>Password Requirements</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Password minimum length <br />
        8 character(s)
        <br />
        Password requirements
        <br />
        Contains at least 1 number
        <br />
        Contains at least 1 special character
        <br />
        Contains at least 1 uppercase letter
        <br />
        Contains at least 1 lowercase letter
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PasswordRequirement;
