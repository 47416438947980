import React from "react";
import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getUserTenantId } from "../../../../utils/ALL/localStorageUtils";
import OriginReportsNavBar from "./1001/OriginReportsNavBar";
import { TENANT_1001 } from "../../../../utils/ALL/tenantUtils";

//add validation if user can see reports tab
const AUCommonReportsNavBar = ({ accessGroups }) => {
  const tenantId = getUserTenantId();

  if (tenantId === TENANT_1001) {
    return <OriginReportsNavBar accessGroups={accessGroups}></OriginReportsNavBar>;
  } else if (tenantId === "1005") {
    // return null;
    // TODO: uncomment when ready to be used (Updated Code)
    return (
      <NavDropdown title="Reports" id="header-nav-dropdown-admin">
        <NavDropdown.Item as={Link} to="/gateway-throughput">
          Gateway Throughput
        </NavDropdown.Item>
      </NavDropdown>
    );
  } else {
    return null;
  }
};
//
export default AUCommonReportsNavBar;
