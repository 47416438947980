import { Auth } from "aws-amplify";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "./ExpiredSessionModal.css";
const ExpiredSessionModal = ({ isShowModal }) => {
  const [modalShowStatus, setModalShowStatus] = useState(isShowModal);
  const handleClose = async (e) => {
    e.preventDefault();
    await Auth.signOut();
    setModalShowStatus(false);
  };

  return (
    <Modal
      backdrop="static"
      keyboard={false}
      show={modalShowStatus}
      onHide={handleClose}
      contentClassName="session-expired-modal"
      data-test="expired-session-modal"
    >
      <Modal.Body>Your session has expired. You are automatically been Log Out. Please log in again.</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Proceed
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExpiredSessionModal;
