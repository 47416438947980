import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

const EQLSearchNav = () => {
  return (
    <NavDropdown title="Search" id="header-nav-dropdown-admin">
      <NavDropdown.Item as={Link} to="/search">
        Transaction Search
      </NavDropdown.Item>
      <NavDropdown.Item as={Link} to="/nmi-routing-search">
        NMI Routing Table
      </NavDropdown.Item>
    </NavDropdown>
  );
};

export default EQLSearchNav;
