import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

const NZCommonAdminNav = ({ active, handleOnCLickDropDownMenu }) => {
  return (
    <NavDropdown title="Admin" active={active} id="header-nav-dropdown-admin">
      <NavDropdown.Item as={Link} to="/file-upload" onClick={handleOnCLickDropDownMenu}>
        File Upload
      </NavDropdown.Item>
    </NavDropdown>
  );
};

export default NZCommonAdminNav;
