import { Auth } from "aws-amplify";
import axios from "axios";
import { log } from "./ALL/loggingUtils";

export const HTTP_STATUS_CODE_200 = 200;
export const HTTP_STATUS_CODE_400 = 400;
export const HTTP_STATUS_CODE_401 = 401;
export const HTTP_STATUS_CODE_504 = 504;

let isRefreshTokenExpired = false;

const getUserJwtToken = async () => {
  return await Auth.currentSession()
    .then((currentSession) => currentSession.getIdToken().getJwtToken())
    .catch((err) => {
      log(err);
      isRefreshTokenExpired = true;
      log("Session expired!");
    });
};

export const doGet = async (getUrl, getParams) => {
  log("Executing GET request.");
  let getRequest = {
    method: "GET",
    url: getUrl,
    headers: {
      Authorization: `Bearer ${await getUserJwtToken()}`,
    },
    params: getParams,
  };

  log(getRequest);

  return await axios
    .request(getRequest)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      log(error);
      if (error.code === "ERR_NETWORK") {
        return error.response.code === 401;
      }
      return error.response;
    });
};

/**
 * TODO: document this function
 * @returns
 */
export const doPost = async (postUrl, postData) => {
  log("Executing POST request.");
  let postRequest = {
    method: "POST",
    url: postUrl,
    headers: {
      Authorization: `Bearer ${await getUserJwtToken()}`,
    },
    data: postData,
  };

  let maxRetry = 3;
  let currentTry = 0;
  let success = false;
  let responseToReturn;

  while (currentTry < maxRetry) {
    currentTry++;
    log(`Getting data. Attempt ${currentTry} of ${maxRetry}`);

    await axios
      .request(postRequest)
      .then((response) => {
        responseToReturn = response;
        success = true;
        log("Done getting data");
      })
      .catch((error) => {
        responseToReturn = error.response;
        success = false;
      });
    if (success) {
      break;
    }
  }

  return responseToReturn;
};

export const doPost1Try = async (postUrl, postData) => {
  log("Executing POST request.");
  let postRequest = {
    method: "POST",
    url: postUrl,
    headers: {
      Authorization: `Bearer ${await getUserJwtToken()}`,
    },
    data: postData,
  };

  return await axios
    .request(postRequest)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      log(error);
      if (error.code === "ERR_NETWORK") {
        return error.response.code === 401;
      }
      return error.response;
    });
};

export const doPostRetryOnTimeout = async (postUrl, postData) => {
  log("Executing POST request.");
  let postRequest = {
    method: "POST",
    url: postUrl,
    headers: {
      Authorization: `Bearer ${await getUserJwtToken()}`,
    },
    data: postData,
  };

  let maxRetry = 3;
  let currentTry = 0;
  let success = false;
  let responseToReturn;

  while (currentTry < maxRetry) {
    currentTry++;
    log(`Getting data. Attempt ${currentTry} of ${maxRetry}`);

    await axios
      .request(postRequest)
      .then((response) => {
        responseToReturn = response;
        success = true;
        log("Done getting data");
      })
      .catch((error) => {
        log(error);
        responseToReturn = error.response;
        // log(responseToReturn);

        if (error.code === HTTP_STATUS_CODE_504) {
          log("GatewayTimeout, retrying");
          success = false;
        } else {
          success = true;
        }
      });
    if (success) {
      break;
    }
  }

  return responseToReturn;
};

export const doPostPinUpload = async (postUrl, postData) => {
  log("Executing POST request.");
  let postRequest = {
    method: "POST",
    url: postUrl,
    headers: {
      Authorization: `Bearer ${await getUserJwtToken()}`,
      "Content-Type": "text/plain",
    },
    data: postData,
  };

  if (isRefreshTokenExpired) {
    return { data: [] };
  } else {
    return await axios
      .request(postRequest)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        log(error);
        if (error.code === "ERR_NETWORK") {
          log("network error");
        }

        return { data: [] };
      });
  }
};
