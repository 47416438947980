import { Amplify, Auth } from "aws-amplify";
import React, { useState } from "react";
import { Button, Form, Image, Spinner } from "react-bootstrap";
import "./SignIn.css";
import SendMFACode from "../SendMFACode";
import FederatedSignIn from "../FederatedSignIn";
import RegularSignIn from "../RegularSignIn/RegularSignIn";
import ChangePasswordFirstLogin from "../ChangePasswordFirstLogin/ChangePasswordFirstLogin";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import SetUpMFADevice from "../SetUpMFADevice/SetUpMFADevice";
import ForgotPasswordNewPassword from "../ForgotPasswordNewPassword.test/ForgotPasswordNewPassword";
import RealmsPage from "../../../RealmsPage/RealmsPage";
import { log } from "../../../../utils/ALL/loggingUtils";

//DON'T DELETE, FOR REFERENCE
//   Amplify.configure({
//     aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION, // (required) - Region where Amazon Cognito project was created
//     aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID, // (optional) -  Amazon Cognito User Pool ID
//     aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
//   });

Amplify.configure({
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION, // (required) - Region where Amazon Cognito project was created
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID, // (optional) -  Amazon Cognito User Pool ID
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_OAUTH_DOMAIN,
    scope: ["aws.cognito.signin.user.admin", "openid", "profile"],
    redirectSignIn: process.env.REACT_APP_OAUTH_REDIRECT_SIGN_IN,
    redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_SIGN_OUT,
    responseType: "code",
  },
});

const updatedAmplifyConfigForAcn = async (e) => {
  e.preventDefault();
  log("reconfiguring for sso sign in");

  Amplify.configure({
    aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION, // (required) - Region where Amazon Cognito project was created
    aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID_ACCENTURE, // (optional) -  Amazon Cognito User Pool ID
    aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID_ACCENTURE,
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      scope: ["openid", "profile"],
      redirectSignIn: process.env.REACT_APP_OAUTH_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_SIGN_OUT,
      responseType: "code",
    },
  });

  await Auth.federatedSignIn({ provider: process.env.REACT_APP_IDENTITY_PROVIDER_ACCENTURE });
};

const SignIn = ({ setSignInPage }) => {
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState("");

  const [signInComponentToRender, setSignInComponentToRender] = useState(() => {
    if (process.env.REACT_APP_SIGN_IN_METHOD === "federated") {
      return "FederatedSignIn";
    } else {
      return "RegularSignIn";
    }
  });

  const [currentUser, setCurrentUser] = useState("");

  const componentRenderer = () => {
    switch (signInComponentToRender) {
      case "RegularSignIn":
        return (
          <RegularSignIn
            setIsLoading={setIsLoading}
            setCurrentUser={setCurrentUser}
            setSignInComponentToRender={setSignInComponentToRender}
            setCurrentEmail={setEmail}
            setCurrentPassword={setPassword}
          ></RegularSignIn>
        );
      case "MFACodeRequired":
        return <SendMFACode currentUser={currentUser} setIsLoading={setIsLoading}></SendMFACode>;
      case "UserForgotPassword":
        return (
          <ForgotPassword
            setIsLoading={setIsLoading}
            setSignInComponentToRender={setSignInComponentToRender}
            setCurrentEmail={setEmail}
          ></ForgotPassword>
        );
      case "ChangePasswordAfterCodeSent":
        return (
          <ForgotPasswordNewPassword
            setIsLoading={setIsLoading}
            setSignInComponentToRender={setSignInComponentToRender}
            userEmail={email}
          ></ForgotPasswordNewPassword>
        );

      case "FirstLoginChangePassword":
        return (
          <ChangePasswordFirstLogin
            currentEmail={email}
            currentPassword={password}
            setIsLoading={setIsLoading}
            currentUser={currentUser}
            setSignInComponentToRender={setSignInComponentToRender}
          ></ChangePasswordFirstLogin>
        );
      case "SetUpMFADevice":
        return <SetUpMFADevice currentUser={currentUser} setIsLoading={setIsLoading}></SetUpMFADevice>;
      case "FederatedSignIn":
        return <FederatedSignIn setSignInPage={setSignInPage}></FederatedSignIn>;
      case "RealmsPageLogin":
        return <RealmsPage></RealmsPage>;
      default:
        break;
    }
  };

  return (
    <>
      <Form className="regular-sign-in-form mt-5" data-test="sign-in-form">
        <Form.Group className="table-banner-form-group">
          <table className="sign-in-banner table-banner">
            <tbody>
              <tr>
                <td>
                  <Image
                    src="/images/accenture/acc_logo_white_purple.png"
                    alt="85x90"
                    className="sign-banner-acn-brand"
                  />
                </td>
                <td>
                  <div className="vertical-line"></div>
                </td>
                <td className="td-mdh-text">Market Data Hub</td>
              </tr>
            </tbody>
          </table>
        </Form.Group>
        {componentRenderer()}
        <div style={{ height: "200px" }}></div>
        <div className="alternative-sign-in-button-wrapper">
          <span className="alternative-sign-in-text">Alternative Sign In</span>
          <br></br>
          {/* <Button className="alternative-sign-in-btn" variant=""> */}
          <Image
            src="/images/accenture/Acc_Logo_Black_Purple_RGB.png"
            width={80}
            height={"auto"}
            className="alternative-sign-in-acn"
            // onClick={() => {
            //   log("accenture log in");
            //   updatedAmplifyConfigForAcn();
            //   // Auth.federatedSignIn({ provider: process.env.REACT_APP_IDENTITY_PROVIDER_ACCENTURE });
            // }}
            onClick={updatedAmplifyConfigForAcn}
          ></Image>
          {/* </Button> */}
        </div>
      </Form>

      {isLoading ? (
        <div className="spinner-div">
          <Spinner animation="border" variant="danger" />
        </div>
      ) : null}
    </>
  );
};

export default SignIn;
