import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import formatXml from "xml-formatter";

const FileViewer = ({
  fileType,
  fileContent,
  transactionId,
  setShowModal,
  showModal,
  isFetchingS3Data,
  xmlFileType,
  errorMessage,
}) => {
  const handleClose = () => setShowModal(false);

  const generateModalTitle = () => {
    if (isFetchingS3Data) {
      return "Processing...";
    } else {
      if (fileType === "xml") {
        if (xmlFileType === "aseXml") {
          return `${"ase xml".toUpperCase()} Data of: ${transactionId}`;
        } else if (xmlFileType === "preAseXml") {
          return `${"pre ase xml".toUpperCase()} Data of: ${transactionId}`;
        } else {
          return null;
        }
      } else {
        return `${fileType.toUpperCase()} Data of: ${transactionId}`;
      }
    }
  };

  const modalBodyToShow = () => {
    if (isFetchingS3Data) {
      return (
        <Modal.Body>
          <div className="spinner-div">
            <Spinner animation="border" variant="danger" />
          </div>
        </Modal.Body>
      );
    } else if (fileContent === "") {
      if (errorMessage) {
        return <Modal.Body>{errorMessage}</Modal.Body>;
      } else {
        return <Modal.Body>No data found</Modal.Body>;
      }
    } else {
      if (fileType === "json") {
        return (
          <Modal.Body>
            <pre id="json" className="json-contents">
              <code>{JSON.stringify(fileContent, undefined, 2)}</code>
            </pre>
          </Modal.Body>
        );
      } else if (fileType === "csv") {
        return (
          <Modal.Body>
            <pre id="csv" className="csv-contents">
              <code>{fileContent}</code>
            </pre>
          </Modal.Body>
        );
      } else if (fileType === "xml") {
        return (
          <Modal.Body>
            <pre id="xml" className="xml-contents">
              <code>{formatXml(fileContent, { collapseContent: true })}</code>
            </pre>
          </Modal.Body>
        );
      }
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        contentClassName="data-viewer-modal-content"
        dialogClassName="file-viewer-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{generateModalTitle()}</Modal.Title>
        </Modal.Header>

        {modalBodyToShow()}
      </Modal>
    </>
  );
};

export default FileViewer;
