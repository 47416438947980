import { getUserCountry } from "../../../utils/ALL/localStorageUtils";
import NZMasterTxnDetailViewContainer from "./NZ/NZMasterTxnDetailViewContainer";
import "./MasterTransactionDetails.css";
import AUMasterTxnDetailViewContainer from "./AU/AUMasterTxnDetailViewContainer";
const MasterTxnDetailsViewHandler = () => {
  const tenantCountry = getUserCountry().toUpperCase();
  if (tenantCountry === "AU") {
    return <AUMasterTxnDetailViewContainer></AUMasterTxnDetailViewContainer>;
  } else if (tenantCountry === "NZ") {
    return <NZMasterTxnDetailViewContainer></NZMasterTxnDetailViewContainer>;
  } else {
    return null;
  }
};

export default MasterTxnDetailsViewHandler;
