import React from "react";
import AUCommonReportsNavBar from "./AU/AUCommonReportsNavBar";
import { getUserCountry } from "../../../utils/ALL/localStorageUtils";
import { COUNTRY_AU } from "../../../utils/ALL/tenantUtils";

const ReportsNavigationBar = ({ active, handleOnCLickDropDownMenu, accessGroups }) => {
  /** add validation if other countries will have a reports tab */
  const tenantCountry = getUserCountry().toUpperCase();
  if (tenantCountry === COUNTRY_AU) {
    return <AUCommonReportsNavBar accessGroups={accessGroups}></AUCommonReportsNavBar>;
  } else {
    return null;
  }
};

export default ReportsNavigationBar;
