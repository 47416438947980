import moment from "moment-timezone";

export const DATE_FORMAT_1 = "YYYY-MM-DD";
export const DATE_FORMAT_2 = "YYYY/MM/DD";
export const DATE_FORMAT_3 = "YYYY/MM/DD HH:mm";
export const DATE_FORMAT_4 = "DD MMMM YYYY HH:mm";
export const DATE_FORMAT_5 = "HH:mm:ss";
export const DATE_FORMAT_6 = "DD/MM/YYYY HH:mm:ss";

export const DATE_CREATED = "Date Created";
export const DATE_CREATED_AEST = "Date Created [AEST]";
export const DATE_CREATED_AEDT = "Date Created [AEDT]";
export const DATE_CREATED_UTC = "Date Created [UTC]";

export const AEDT = "AEDT";
export const AEST = "AEST";

export const getCurrentAuTime = () => {
  return moment.tz("Australia/Sydney");
};

export const isAuSydneyInDST = () => {
  return moment.tz("Australia/Sydney").isDST();
};

export const determineIfAestOrAedt = () => {
  if (getCurrentAuTime().isDST()) {
    return AEDT;
  } else {
    return AEST;
  }
};

/**
 * use this function for formatting a date time, disregarding there time zone.
 * @param {string} dateTime
 * @param {string} format
 * @returns formatted date time string
 */
export const formatDateTimeTypeA = (dateTime, format) => {
  return moment.utc(dateTime).utc(true).format(format);
};

export const convertUtcDateTimeToAest = (dateTime) => {
  return moment(dateTime).utc(false).add(10, "hours");
};

export const convertUtcDateTimeToAestAndFormat = (dateTime, format) => {
  return convertUtcDateTimeToAest(dateTime).format(format);
};

export const convertUtcDateTimeToAedt = (dateTime) => {
  return moment(dateTime).utc(false).add(11, "hours");
};

export const convertUtcDateTimeToAedtAndFormat = (dateTime, format) => {
  return convertUtcDateTimeToAedt(dateTime).format(format);
};
