/** Accenture User groups */
export const ACC_MDH_ACCESS = "ACC_MDH_ACCESS";
export const ACC_MDH_ADMIN = "ACC_MDH_ADMIN";

/** Origin user groups base from dons email: RE: Portal user group for EQL reports page */
/** Non-prod groups Origin */
export const NPE_MDH_CREATE = "NPE_MDH_CREATE"; //Access admin tab in NPE
export const NPE_MDH_REPORT = "NPE_MDH_REPORT"; //	Access report tab in NPE
export const NPE_MDH_VIEW = "NPE_MDH_VIEW"; //Access search tab in NPE
export const ACC_MDH_Access = "ACC_MDH_Access"; //	Access all tabs
/** Prod groups Origin */
export const PROD_MDH_CREATE = "PROD_MDH_CREATE"; //Access admin tab in Prod
export const PROD_MDH_VIEW = "PROD_MDH_VIEW"; //	Access search tab in Prod
export const PROD_MDH_REPORT = "PROD_MDH_REPORT"; //Access report tab in Prod

/**Access group for 1002 */
export const PORTAL_ACCESS_1002 = "1002";

/**Access group for 1002 */
export const PORTAL_ACCESS_1003 = "1003";
