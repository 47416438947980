export const MASTER_TXN_STATUS_CAN = "CAN"; //CANCELLED
export const MASTER_TXN_STATUS_COM = "COM"; //COMPLETE
export const MASTER_TXN_STATUS_CRT = "CRT"; //wala
export const MASTER_TXN_STATUS_NCOM = "NCOM"; //NOT_COMPLETED
export const MASTER_TXN_STATUS_OBJ = "OBJ"; //OBJECTED
export const MASTER_TXN_STATUS_PACK = "PACK"; //PENDING_ACKNOWLEDGEMENT
export const MASTER_TXN_STATUS_PACN = "PACN"; // PENDING_ACKNOWLEDGEMENT_NOTIFICATION
export const MASTER_TXN_STATUS_PART = "PART"; //PARTIALLY_COMPLETED
export const MASTER_TXN_STATUS_PEND = "PEND"; //PENDING
export const MASTER_TXN_STATUS_PVAL = "PVAL"; //PENDING_VALIDATION
export const MASTER_TXN_STATUS_PVLN = "PVLN"; //PENDING_VALIDATION_NOTIFICATION
export const MASTER_TXN_STATUS_PVLR = "PVLR"; //PENDING_VALIDATION_REQUEST
export const MASTER_TXN_STATUS_REJ = "REJ"; //REJECTED
export const MASTER_TXN_STATUS_REJN = "REJN"; //REJECTED_NOTIFICATION
export const MASTER_TXN_STATUS_REJR = "REJR"; //REJECTED_REQUEST
export const MASTER_TXN_STATUS_REQ = "REQ"; //REQUESTED
export const MASTER_TXN_STATUS_NACC = "NACC"; //NO_ACCESS
export const MASTER_TXN_STATUS_PADD = "PADD"; //PEND_ADD_DATA
export const MASTER_TXN_STATUS_PEN = "PEN"; //PENDING
export const MASTER_TXN_STATUS_RDF = "RDF"; //READ_FAILURE

export const CANCELLED = "Cancelled";
export const COMPLETE = "Complete";
export const NOT_COMPLETED = "Not Completed";
export const OBJECTED = "Objected";
export const PENDING_ACKNOWLEDGEMENT = "Pending Acknowledgement";
export const PENDING_ACKNOWLEDGEMENT_NOTIFICATION = "Pending Ack (Notification)";
export const PARTIALLY_COMPLETED = "Partially Completed";
export const PENDING = "Pending";
export const PENDING_VALIDATION = "Pending Validation";
export const PENDING_APPROVED = "Pending Approved";
export const PENDING_VALIDATION_NOTIFICATION = "Pending Validation (Notification)";
export const PENDING_VALIDATION_REQUEST = "Pending Validation (Request)";
export const REJECTED = "Rejected";
export const REJECTED_NOTIFICATION = "Rejected (Notification)";
export const REJECTED_REQUEST = "Rejected (Request)";
export const REQUESTED = "Requested";
export const NO_ACCESS = "No Access";
export const PEND_ADD_DATA = "Pend Add Data";
export const READ_FAILURE = "Read Failure";

export const auMasterTxnStatusFormatter = (masterTxnStatusCode) => {
  switch (masterTxnStatusCode.toUpperCase()) {
    case MASTER_TXN_STATUS_CAN:
      return CANCELLED;
    case MASTER_TXN_STATUS_COM:
      return COMPLETE;
    case MASTER_TXN_STATUS_NCOM:
      return NOT_COMPLETED;
    case MASTER_TXN_STATUS_OBJ:
      return OBJECTED;
    case MASTER_TXN_STATUS_PACK:
      return PENDING_ACKNOWLEDGEMENT;
    case MASTER_TXN_STATUS_PACN:
      return PENDING_ACKNOWLEDGEMENT_NOTIFICATION;
    case MASTER_TXN_STATUS_PART:
      return PARTIALLY_COMPLETED;
    case MASTER_TXN_STATUS_PEND:
      return PENDING;
    case MASTER_TXN_STATUS_PVAL:
      return PENDING_VALIDATION;
    case MASTER_TXN_STATUS_PVLN:
      return PENDING_VALIDATION_NOTIFICATION;
    case MASTER_TXN_STATUS_PVLR:
      return PENDING_VALIDATION_REQUEST;
    case MASTER_TXN_STATUS_REJ:
      return REJECTED;
    case MASTER_TXN_STATUS_REJN:
      return REJECTED_NOTIFICATION;
    case MASTER_TXN_STATUS_REJR:
      return REJECTED_REQUEST;
    case MASTER_TXN_STATUS_REQ:
      return REQUESTED;
    case MASTER_TXN_STATUS_NACC:
      return NO_ACCESS;
    case MASTER_TXN_STATUS_PADD:
      return PEND_ADD_DATA;
    case MASTER_TXN_STATUS_PEN:
      return PENDING;
    case MASTER_TXN_STATUS_RDF:
      return READ_FAILURE;
    default:
      return masterTxnStatusCode.toUpperCase();
  }
};
