import BootstrapTable from "react-bootstrap-table-next";
import { determineTenantName } from "../../../../utils/ALL/tenantUtils";
import { createdDateFormatter, nzStatusFormatter, rowStyler, sourceFormatter } from "./ICPCommonSearchResultUtils";

import paginationFactory from "react-bootstrap-table2-paginator";
import { log } from "../../../../utils/ALL/loggingUtils";

const ICPCommonSearchResult = ({ apiResponseData }) => {
  const marketFormatter = (cell, row) => {
    if (row.masterTxnType === "MTRD") return "MEP";
    else if (row.masterTxnType === "SWITCH") return "REGISTRY";
  };
  const generateMasterTxnDetailsUrl = (row) => {
    const url = `/masterTxnDetails?masterTxnId=${row.masterTxnId}&status=${row.status}&dateCreated=${row.dateInitiated}&txnType=${row.masterTxnType}&sender=${row.sender}&icp=${row.meterPointID}`;

    log(url);

    return url;
  };
  const masterTransactionIdFormatter = (cell, row) => {
    return (
      <a
        onClick={() => {
          window.open(generateMasterTxnDetailsUrl(row));
        }}
      >
        {cell}
      </a>
    );
  };
  const columns = [
    {
      dataField: "masterTxnId",
      text: "Master Transaction ID",
      formatter: masterTransactionIdFormatter,
      classes: "master-txn-id-td",
    },
    {
      dataField: "meterPointID",
      text: "ICP",
    },
    {
      dataField: "dateInitiated",
      //octo nz currently uses a fixed UTC time
      text: "Date Created [UTC]",
      formatter: createdDateFormatter,
      sort: true,
    },
    {
      dataField: "abc", //added so that the console will throw error
      text: "Market",
      formatter: marketFormatter,
    },
    {
      dataField: "masterTxnType",
      text: "Transaction Type",
    },

    {
      dataField: "status",
      text: "Status",
      formatter: nzStatusFormatter,
    },

    {
      dataField: "sender",
      text: "Source",
      formatter: sourceFormatter,
    },
  ];
  const pageButtonRenderer = (data) => {
    let { page, active, disabled, title, onPageChange } = data;

    const onClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };

    let activeStyle = {};
    if (active) {
      activeStyle.backgroundColor = "black";
      activeStyle.color = "white";
    } else {
      activeStyle.backgroundColor = "white";
      activeStyle.color = "gray";
    }
    return (
      <li key={page} className="page-item">
        <a style={activeStyle} onClick={onClick}>
          {page}
        </a>
      </li>
    );
  };
  const paginationOptions = {
    sizePerPage: 10, // Specify the size per page. It's necessary when remote is enabled
    pageStartIndex: 1, // first page will be 0, default is 1
    paginationSize: 5, // the pagination bar size, default is 5
    showTotal: true, // display pagination information
    withFirstAndLast: true, // hide the going to first and last page button
    alwaysShowAllBtns: true, // always show the next and previous page button
    firstPageText: "First", // the text of first page button
    prePageText: "Prev", // the text of previous page button
    nextPageText: "Next", // the text of next page button
    lastPageText: "Last", // the text of last page button
    nextPageTitle: "Go to next", // the title of next page button
    prePageTitle: "Go to previous", // the title of previous page button
    firstPageTitle: "Go to first", // the title of first page button
    lastPageTitle: "Go to last", // the title of last page button
    hideSizePerPage: true, // hide the size per page dropdown
    hidePageListOnlyOnePage: true, // hide pagination bar when only one page, default is false
    pageButtonRenderer,
  };

  return (
    <>
      {apiResponseData.length > 0 ? (
        <BootstrapTable
          columns={columns}
          keyField="masterTxnId"
          data={apiResponseData}
          pagination={paginationFactory(paginationOptions)}
          bootstrap4
          rowStyle={rowStyler}
          classes="search-result-table"
          // defaultSorted={defaultSorted}
          wrapperClasses="search-result-wrapper"
          headerWrapperClasses={determineTenantName()}
          data-test="search-result-table"
        ></BootstrapTable>
      ) : null}
    </>
  );
};

export default ICPCommonSearchResult;
