import moment from "moment-timezone";

export const createdDateFormatter = (cell, row) => {
  return moment(cell, "YYYY.MM.DD.HH.mm.ss").utc(true).format("DD/MM/YYYY HH:mm");
};

export const nzStatusFormatter = (cell) => {
  switch (cell.toUpperCase()) {
    //Common txn status
    case "REQ":
      return "Requested";
    case "COM":
      return "Complete";
    //NZ txn status

    case "INPROG":
      return "In Progress";

    case "CANCELLATIONREQUESTED":
      return "Cancellation Requested";
    case "CANCELLATIONREJECTED":
      return "Cancellation Rejected";
    case "REPLACEMENTREADREQUESTED":
      return "Replacement Read Requested";
    case "REPLACEMENTREADACCEPTED":
      return "Replacement Read Accepted";
    case "REPLACEMENTREADREJECTED":
      return "Replacement Read Rejected";
    case "CANCELLED":
      return "Cancelled";

    default:
      return cell.toUpperCase();
  }
};

export const sourceFormatter = (cell, row) => {
  if (cell) {
    return cell.toUpperCase();
  } else {
    return "";
  }
};

export const rowStyler = (row, rowIndex) => {
  row.index = rowIndex;
  const style = {};
  if (rowIndex % 2 === 0) {
    style.backgroundColor = "#E7E7E7";
  } else {
    style.backgroundColor = "#FFFFFF";
  }
  style.borderTop = "none";

  return style;
};
