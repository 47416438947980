import { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import { Auth, Hub } from "aws-amplify";

import ExpiredSessionModal from "./Components/Common/Authentication/ExpiredSessionModal/ExpiredSessionModal";
import FooterSignIn from "./Components/Common/Authentication/FooterSignIn/FooterSignIn";
import HeaderSignIn from "./Components/Common/Authentication/HeaderSignIn/HeaderSignIn";
import SignIn from "./Components/Common/Authentication/SignIn/SignIn";
import Header from "./Components/Common/Header/Header";
import Footer from "./Components/Common/Footer/Footer";
import SearchViewHandler from "./Components/Search/SearchViewHandler";
import MasterTxnDetailsViewHandler from "./Components/MasterTransactionDetailsView/MasterTransactionDetailsViewHandlers/MasterTxnDetailsViewHandler";
import FileUploadHandler from "./Components/FileUpload/FileUploadHandler";
import GatewayThroughputHandler from "./Components/Reports/GatewayThroughput/GatewayThroughputHandler";
import Unauthorised from "./Components/Common/Unauthorised/Unauthorised";
import RealmsPage from "./Components/RealmsPage/RealmsPage";
import NMIRoutingSearchView from "./Components/NMIRoutingSearch/NMIRoutingSearchView";
import UnrecognizedPath from "./Components/Common/UnrecognizedPath/UnrecognizedPath";
import AUServiceOrderReports from "./Components/Reports/ServiceOrderReport/AU/AUServiceOrderReports";
import AUHealthCheckReports from "./Components/Reports/HealthCheckReport/AU/AUHealthCheckReports";
import AUUndeliveredMessageReports from "./Components/Reports/UndeliveredMessageReport/AU/AUUndeliveredReports";
import AUMeteringReports from "./Components/Reports/MeteringData/AU/AUMeteringReport";
import { getUserTenantId } from "./utils/ALL/localStorageUtils";
import { TENANT_1001, TENANT_1002, TENANT_1003, TENANT_1004, TENANT_1005 } from "./utils/ALL/tenantUtils";
import {
  PORTAL_ACCESS_1002,
  ACC_MDH_ACCESS,
  ACC_MDH_Access,
  NPE_MDH_REPORT,
  NPE_MDH_VIEW,
  PROD_MDH_REPORT,
  PROD_MDH_VIEW,
  PORTAL_ACCESS_1003,
} from "./utils/ALL/accessGroups";
import { log } from "./utils/ALL/loggingUtils";
function App() {
  const [user, setUser] = useState(null);
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [signInPage, setSignInPage] = useState("select-login");
  const [accessGroups, setAccessGroups] = useState([]);
  const TENANT_ID = getUserTenantId();
  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data, message } }) => {
      switch (event) {
        case "signIn":
          log("1: signIn");
          getUser().then((userData) => {
            setAuthenticatedUser(userData);
          });
          break;
        case "cognitoHostedUI":
          log("2: cognitoHostedUI");
          getUser().then((userData) => {
            setAuthenticatedUser(userData);
          });
          break;
        case "cognitoHostedUI_failure":
          log("Sign in failure");
          log("cognitoHostedUI_failure");
          break;
        case "signOut":
          log("3: signout");
          setUser(null);
          break;
        case "signIn_failure":
          log("4: signIn_failure");
          if (Error(data).message.includes("NotAuthorizedException")) {
            return alert("Invalid credentials");
          }
          break;
        case "tokenRefresh":
          log("handling tokenRefresh");
          break;
        case "tokenRefresh_failure":
          setIsSessionExpired(true);
          break;
        default:
          log(event + "is not handled");
          break;
      }
    });

    getUser().then((userData) => {
      setAuthenticatedUser(userData);
    });
  }, []);

  const getUser = () => {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => {
        log("Not signed in");
      });
  };

  const setAuthenticatedUser = (userData) => {
    log(userData);

    setUser(userData);

    if (userData != null) {
      /** Check  "custom:group" it might change for other clients*/
      let customGroupString = userData.signInUserSession.idToken.payload["custom:group"];

      if (customGroupString) {
        // Check if custom:groups attribute is in array type
        if (customGroupString.charAt(0) === "[" && customGroupString.charAt(customGroupString.length - 1) === "]") {
          customGroupString = customGroupString.slice(1, customGroupString.length - 1);
        }

        let customGroupArray = customGroupString.split(", ");
        // log(customGroupArray);

        setAccessGroups(customGroupArray);
      } else {
        //when using cognito:groups the value is already an array
        customGroupString = userData.signInUserSession.idToken.payload["cognito:groups"];
        setAccessGroups(customGroupString);
      }

      log(customGroupString);
    }
  };

  const determineSignPage = () => {
    switch (signInPage.toLowerCase()) {
      case "select-login":
        return (
          <>
            <RealmsPage setSignInPage={setSignInPage}></RealmsPage>
          </>
        );
      case "user-pass-login":
        return (
          <>
            <div className="div-sign-in-wrapper">
              <HeaderSignIn />
              <SignIn setSignInPage={setSignInPage} />
              <FooterSignIn />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const isUserAllowedToViewReportsTab = () => {
    let tenantId = getUserTenantId();
    if (tenantId === TENANT_1001) {
      //Add validation for origin users
      return (
        accessGroups.includes(ACC_MDH_ACCESS) ||
        accessGroups.includes(ACC_MDH_Access) ||
        accessGroups.includes(NPE_MDH_REPORT) ||
        accessGroups.includes(PROD_MDH_REPORT)
      );
    } else if (tenantId === TENANT_1002) {
      return false;
    } else if (tenantId === TENANT_1005) {
      return true;
    } else {
      return false;
    }
  };

  const isUserAllowedToViewSearchTab = () => {
    if (TENANT_ID === TENANT_1001) {
      return (
        accessGroups.includes(ACC_MDH_ACCESS) ||
        accessGroups.includes(ACC_MDH_Access) ||
        accessGroups.includes(NPE_MDH_VIEW) ||
        accessGroups.includes(PROD_MDH_VIEW)
      );
    } else if (TENANT_ID === TENANT_1002 || TENANT_ID === TENANT_1003 || TENANT_ID === TENANT_1005) {
      return true;
    } else {
      return false;
    }
  };

  const isAllowedToViewPortal = () => {
    // if there are no data for accessGroups it will return false
    if (accessGroups) {
      switch (TENANT_ID) {
        case TENANT_1001:
          return (
            accessGroups.includes(ACC_MDH_ACCESS) ||
            accessGroups.includes(ACC_MDH_Access) ||
            accessGroups.includes(NPE_MDH_VIEW) ||
            accessGroups.includes(PROD_MDH_VIEW)
          );
        case TENANT_1002:
          return accessGroups.includes(PORTAL_ACCESS_1002) || accessGroups.includes(ACC_MDH_ACCESS);
        case TENANT_1003:
          return accessGroups.includes(PORTAL_ACCESS_1003) || accessGroups.includes(ACC_MDH_ACCESS);
        case TENANT_1005:
          return true;
        default:
          return false;
      }
    } else {
      return false;
    }
  };

  const allowedTenantRoutes = () => {
    const tenantId = getUserTenantId();
    switch (tenantId) {
      case TENANT_1001:
        return (
          <>
            {isUserAllowedToViewReportsTab ? (
              <>
                <Route path="/health-check" exact element={<AUHealthCheckReports />}></Route>
                <Route path="/so-report" exact element={<AUServiceOrderReports />}></Route>
                <Route path="/undelivered-messages" exact element={<AUUndeliveredMessageReports />}></Route>
                <Route path="/metering-data" exact element={<AUMeteringReports />}></Route>
              </>
            ) : null}
          </>
        );

      case TENANT_1002:
        return null;
      case TENANT_1003:
        return <Route path="/file-upload" exact element={<FileUploadHandler />}></Route>;
      case TENANT_1004:
        return null;
      case TENANT_1005:
        return (
          <>
            {isUserAllowedToViewSearchTab ? (
              <Route path="/nmi-routing-search" exact element={<NMIRoutingSearchView />}></Route>
            ) : null}
            {isUserAllowedToViewReportsTab ? (
              <Route path="/gateway-throughput" exact element={<GatewayThroughputHandler />}></Route>
            ) : null}
          </>
        );

      default:
        return null;
    }
  };

  return (
    <>
      {user ? (
        <>
          {isAllowedToViewPortal() ? (
            <>
              <Header
                accessGroups={accessGroups}
                isUserAllowedToViewReportsTab={isUserAllowedToViewReportsTab}
                isUserAllowedToViewSearchTab={isUserAllowedToViewSearchTab}
              ></Header>
              <Routes>
                {/** Common path for all tenants */}
                <Route path="/" exact element={<Navigate to={"/search"} />}></Route>
                {isUserAllowedToViewSearchTab() ? (
                  <>
                    <Route path="/search" exact element={<SearchViewHandler />}></Route>
                    <Route path="/masterTxnDetails" element={<MasterTxnDetailsViewHandler />}></Route>
                  </>
                ) : null}

                {allowedTenantRoutes()}

                {/** Handle path that is not declared */}
                <Route path="*" exact element={<UnrecognizedPath />}></Route>
              </Routes>
              <Footer></Footer>
            </>
          ) : (
            <Unauthorised></Unauthorised>
          )}
        </>
      ) : (
        <div className="div-sign-in-wrapper">
          <HeaderSignIn />
          <SignIn />
          <FooterSignIn />
        </div>
      )}
      {isSessionExpired ? <ExpiredSessionModal isShowModal={true}></ExpiredSessionModal> : null}
    </>
  );
}

export default App;
