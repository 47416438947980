import moment from "moment-timezone";

export const formatDateCreatedUTC = (date) => {
  return moment(date, "YYYY.MM.DD.HH.mm.ss").format("DD/MM/YYYY HH:mm").toString();
};

export const senderFormatter = (cell, row) => {
  return row.sender.toUpperCase();
};

export const rowStyler = (row, rowIndex) => {
  row.index = rowIndex;
  const style = {};
  if (rowIndex % 2 === 0) {
    style.backgroundColor = "#E7E7E7";
  } else {
    style.backgroundColor = "#FFFFFF";
  }
  style.borderTop = "none";

  return style;
};

export const defaultSorted = [
  {
    dataField: "date_created",
    order: "desc",
  },
];
