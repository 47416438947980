import React from "react";
import { determineTenantName } from "../../../../../../../utils/ALL/tenantUtils";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "./ServiceOrderStatusResult.css";
import { Spinner } from "react-bootstrap";
import moment from "moment-timezone";
import paginationFactory from "react-bootstrap-table2-paginator";
import { PAGINATION_OPTIONS_1 } from "../../../../../../../utils/bootStrapTableUtils";
import { DATE_FORMAT_5, DATE_FORMAT_6, formatDateTimeTypeA } from "../../../../../../../utils/dateTimeUtils";
const { ExportCSVButton } = CSVExport;

const ServiceOrderStatusResult = ({
  isFetchingSoAckAndPendingAckVolume,
  soLastAckPendingVolumeResult,
  isFetchingAgingSoList,
  soAgingListResult,
  isFailedToRetrieveSoAckAndPendingAckVolume,
  errorMessageSoAckAndPendingAckVolume,
  isFailedToRetrieveAgingSoList,
  errorMessageAgingSoList,
}) => {
  const soAgeFormatter = (cell, row) => {
    const currentAuTime = moment.tz("Australia/Sydney").utc(true);
    const dateFromTable = moment.utc(cell).tz("Australia/Sydney");

    const timeDifference = currentAuTime - dateFromTable;
    const duration = moment.duration(timeDifference);

    return moment.utc(duration.asMilliseconds()).format("DD HH:mm:ss");
  };

  const columns = [
    {
      isDummyField: true,

      text: "Aging Tag",
      formatter: (cell, row) => {
        return "Aging";
      },
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "sentToMarketDate",
      text: "Age (As of report time)",
      formatter: soAgeFormatter,
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "fromParty",
      text: "From",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "toParty",
      text: "To",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "sentToMarketDate",
      text: "Sent to Market Date",
      headerAlign: "center",
      align: "center",
      formatter: (cell, row) => {
        return formatDateTimeTypeA(cell, DATE_FORMAT_6);
      },
    },
    {
      dataField: "hubAckDate",
      text: "Hub Ack Date",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "appointmentDate",
      text: "Appointment Date",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "nmi",
      text: "NMI",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "soNumber",
      text: "SO Number",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "soType",
      text: "SO Type",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "soSubType",
      text: "SO Sub Type",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "txnRefNum",
      text: "Txn Ref Num",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "status",
      text: "Status",
      headerAlign: "center",
      align: "center",
    },
    {
      isDummyField: true,
      text: "Source System",
      headerAlign: "center",
      align: "center",
    },
  ];
  return (
    <>
      <div className="wrapper-div">
        {isFetchingSoAckAndPendingAckVolume ? (
          <div>
            <Spinner animation="border" variant="danger" />
          </div>
        ) : (
          <>
            {isFailedToRetrieveSoAckAndPendingAckVolume ? (
              <div>{errorMessageSoAckAndPendingAckVolume}</div>
            ) : (
              <>
                <div className={`${determineTenantName()}-gradient`}>Last SO Ack Date/Time</div>
                <div className="bottom-div">
                  Last Ack was:{" "}
                  <strong>{formatDateTimeTypeA(soLastAckPendingVolumeResult[0].lastAckSO, DATE_FORMAT_5)}</strong>
                </div>
                <br></br>
                <div className={`${determineTenantName()}-gradient`}>In Pending SO Ack Volume</div>
                <div className="bottom-div">
                  In Pending Acknowledgement: <strong>{soLastAckPendingVolumeResult[0].allPendingSO}</strong>
                </div>
                <br></br>
              </>
            )}
          </>
        )}

        {isFetchingAgingSoList ? (
          <div>
            <Spinner animation="border" variant="danger" />
          </div>
        ) : (
          <>
            {isFailedToRetrieveAgingSoList ? (
              <div>{errorMessageAgingSoList}</div>
            ) : (
              <>
                <div className={`${determineTenantName()}-gradient`}>Aging SO List</div>
                <div className="bottom-div">
                  SO Aging: <strong>{soAgingListResult.length}</strong>
                  <br></br>
                  {soAgingListResult.length > 0 ? (
                    <ToolkitProvider columns={columns} data={soAgingListResult} bootstrap4 exportCSV>
                      {(props) => (
                        <div>
                          <BootstrapTable
                            {...props.baseProps}
                            classes="search-result-table target-resulting-table"
                            wrapperClasses="search-result-wrapper"
                            headerWrapperClasses={determineTenantName()}
                            pagination={paginationFactory(PAGINATION_OPTIONS_1)}
                            keyField={"soNumber"}
                            condensed={true}
                            striped={true}
                          />
                          <div className="export-csv-button-div-wrapper">
                            <ExportCSVButton {...props.csvProps} className="csv-export-button">
                              Export to CSV
                            </ExportCSVButton>
                          </div>
                        </div>
                      )}
                    </ToolkitProvider>
                  ) : null}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ServiceOrderStatusResult;
