import { Auth } from "aws-amplify";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const SendMFACode = ({ currentUser, setIsLoading }) => {
  const [mfaCode, setMfaCode] = useState("");
  const navigate = useNavigate();
  const sendMFACodeForSignIn = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await Auth.confirmSignIn(currentUser, mfaCode, "SOFTWARE_TOKEN_MFA")
      .then((data) => {
        navigate("/search");
      })
      .catch((err) => {
        console.error(err);
        if (err.message.includes("Invalid code received for user")) {
          alert("MFA code mismatched. Please check the MFA code you entered.");
        }
      });
    setIsLoading(false);
  };
  return (
    <>
      <Form.Group className="child-form" data-test="send-mfa-code-form">
        <Form.Label>MFA Code</Form.Label>
        <Form.Control type="number" onChange={(event) => setMfaCode(event.target.value)} />
        <Button type="submit" onClick={sendMFACodeForSignIn} className="send-mfa-code-button">
          Submit MFA Code
        </Button>
      </Form.Group>
    </>
  );
};

export default SendMFACode;
