import moment from "moment-timezone";

export const formatDateCreatedUTC = (date) => {
  return moment(date, "YYYY.MM.DD.HH.mm.ss").format("DD/MM/YYYY HH:mm").toString();
};

export const sortByDateAscending = (value1, value2) => {
  return (
    moment(value1.date_created, "YYYY.MM.DD.HH.mm.ss").utc().valueOf() -
    moment(value2.date_created, "YYYY.MM.DD.HH.mm.ss").utc().valueOf()
  );
};
