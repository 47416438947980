/** Still being used by eql */
/** TODO: eql must use the NMISearchResultCommon component on the next release for search functionality */
export const auStatusFormatter = (cell) => {
  switch (cell.toUpperCase()) {
    case "REQ":
      return "Requested";
    case "COM":
      return "Complete";
    case "PACK":
      return "Pending Acknowledgement";
    case "PVAL":
      return "Pending Validation";
    case "PEND":
      return "Pending Approved";
    case "CAN":
      return "Cancelled";
    case "REJ":
      return "Rejected";
    case "NCOM":
      return "Not Completed";
    case "OBJ":
      return "Objected";
    default:
      return cell.toUpperCase();
  }
};

/** Still being used by eql */
/** TODO: eql must use the NMISearchResultCommon component on the next release for search functionality */
export const rowStyler = (row, rowIndex) => {
  row.index = rowIndex;
  const style = {};
  if (rowIndex % 2 === 0) {
    style.backgroundColor = "#E7E7E7";
  } else {
    style.backgroundColor = "#FFFFFF";
  }
  style.borderTop = "none";

  return style;
};
