import { getUserTenantId } from "./localStorageUtils";

export const COUNTRY_AU = "AU";
export const COUNTRY_NZ = "NZ";

export const TENANT_1001 = "1001";
export const TENANT_1002 = "1002";
export const TENANT_1003 = "1003";
export const TENANT_1004 = "1004";
export const TENANT_1005 = "1005";

export const determineTenantName = () => {
  let tenantId = getUserTenantId();

  switch (tenantId) {
    case "1001":
      return "origin";
    case "1002":
      return "nectr";
    case "1003":
      return "octo";
    case "1005":
      return "eql";
    default:
      return "default-tenant";
  }
};
