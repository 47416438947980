import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import "./TxnFlowDiagram.css";
import {
  FUEL_TYPE_ELEC,
  FUEL_TYPE_GAS,
  TXN_GROUP_CATS,
  TXN_GROUP_CUST,
  TXN_GROUP_FLDW,
  TXN_GROUP_IAIT,
  TXN_GROUP_MDMT,
  TXN_GROUP_MTRD,
  TXN_GROUP_NETB,
  TXN_GROUP_NMID,
  TXN_GROUP_OWNP,
  TXN_GROUP_SITE,
  TXN_GROUP_SORD,
} from "../../../utils/AU/constantAU";
import {
  MASTER_TXN_STATUS_CAN,
  MASTER_TXN_STATUS_COM,
  MASTER_TXN_STATUS_NACC,
  MASTER_TXN_STATUS_NCOM,
  MASTER_TXN_STATUS_OBJ,
  MASTER_TXN_STATUS_PACK,
  MASTER_TXN_STATUS_PACN,
  MASTER_TXN_STATUS_PADD,
  MASTER_TXN_STATUS_PART,
  MASTER_TXN_STATUS_PEN,
  MASTER_TXN_STATUS_PEND,
  MASTER_TXN_STATUS_PVAL,
  MASTER_TXN_STATUS_PVLN,
  MASTER_TXN_STATUS_PVLR,
  MASTER_TXN_STATUS_RDF,
  MASTER_TXN_STATUS_REJ,
  MASTER_TXN_STATUS_REJN,
  MASTER_TXN_STATUS_REJR,
  MASTER_TXN_STATUS_REQ,
} from "../../../utils/AU/auTxnStatusUtils";
import { log } from "../../../utils/ALL/loggingUtils";

const AUTxnFlowDiagram = ({ txnGroup, txnStatus, fuelType }) => {
  const createTitle = () => {
    if (fuelType === "elec") {
      return "Results from Electricity Database";
    } else if (fuelType === "gas") {
      return "Results from Gas Database";
    } else {
      /** Default to elec when fuel type is not defined */
      return "Results from Electricity Database";
    }
  };

  const transactionTypeImageSelector = () => {
    if (fuelType === FUEL_TYPE_ELEC.toLowerCase()) {
      return elecImageSelector();
    } else if (fuelType === FUEL_TYPE_GAS.toLowerCase()) {
      return gasImageSelector();
    } else {
      /** Default to elec when fuel type is not defined */
      return elecImageSelector();
    }
  };

  const elecImageSelector = () => {
    log(txnGroup);
    if (
      txnGroup === TXN_GROUP_CATS ||
      txnGroup === TXN_GROUP_MTRD ||
      txnGroup === TXN_GROUP_MDMT ||
      txnGroup === TXN_GROUP_NMID
    ) {
      return elecCatsTxnFlowRenderer(txnStatus);
    } else if (txnGroup === TXN_GROUP_SORD || txnGroup === TXN_GROUP_OWNP) {
      return elecSordTxnFlowRenderer(txnStatus);
    } else {
      return elecCustTxnFlowRenderer(txnStatus);
    }
  };

  const elecCatsTxnFlowRenderer = (txnStatus) => {
    let path = "/images/AU/ELEC/CATS/";
    let alt = "";
    switch (txnStatus.toUpperCase()) {
      case MASTER_TXN_STATUS_PACK:
        path = path + "PENDINGACKNOWLEDGEMENT.png";
        alt = "PENDINGACKNOWLEDGEMENT.png";
        break;

      case MASTER_TXN_STATUS_PVAL:
        path = path + "PENDINGVALIDATION.png";
        alt = "PENDINGACKNOWLEDGEMENT.png";
        break;

      case MASTER_TXN_STATUS_REQ:
        path = path + "REQUESTED.png";
        alt = "REQUESTED.png";
        break;

      case MASTER_TXN_STATUS_PEND:
        path = path + "PENDINGAPPROVED.png";
        alt = "PENDINGAPPROVED.png";
        break;

      case MASTER_TXN_STATUS_COM:
        path = path + "COMPLETED.png";
        alt = "COMPLETED.png";
        break;

      case MASTER_TXN_STATUS_CAN:
        path = path + "CANCELLED.png";
        alt = "CANCELLED.png";
        break;
      case MASTER_TXN_STATUS_REJ:
        path = path + "REJECTED.png";
        alt = "REJECTED.png";
        break;
      case MASTER_TXN_STATUS_OBJ:
        path = path + "OBJECTED.png";
        alt = "OBJECTED.png";
        break;
    }
    return <Image src={path} alt={alt} size="100" height="auto" data-test="nz-switch-cancelled-txn-flow"></Image>;
  };

  const elecSordTxnFlowRenderer = (txnStatus) => {
    let path = "/images/AU/ELEC/SORD/";
    let alt = "";

    switch (txnStatus.toUpperCase()) {
      case MASTER_TXN_STATUS_PEND:
        path = path + "PENDINGACKNOWLEDGEMENT.png";
        alt = "PENDINGACKNOWLEDGEMENT.png";
        break;
      case MASTER_TXN_STATUS_CAN:
        path = path + "CANCELLATIONREQUESTED.png";
        alt = "CANCELLATIONREQUESTED.png";
        break;
      case MASTER_TXN_STATUS_COM:
        path = path + "COMPLETED.png";
        alt = "COMPLETED.png";
        break;
      case MASTER_TXN_STATUS_NCOM:
        path = path + "NOTCOMPLETED.png";
        alt = "NOTCOMPLETED.png";
        break;
      case MASTER_TXN_STATUS_PART:
        path = path + "PARTIALLYCOMPLETED.png";
        alt = "PARTIALLYCOMPLETED.png";
        break;
      case MASTER_TXN_STATUS_REQ:
        path = path + "REQUESTED.png";
        alt = "REQUESTED.png";
        break;
      case MASTER_TXN_STATUS_REJ:
        path = path + "REJECTED.png";
        alt = "REJECTED.png";
        break;
    }

    return <Image src={path} alt={alt} size="100" height="auto" data-test="nz-switch-cancelled-txn-flow"></Image>;
  };

  const elecCustTxnFlowRenderer = (txnStatus) => {
    let path = "/images/AU/ELEC/CUST/";
    let alt = "";
    switch (txnStatus.toUpperCase()) {
      case MASTER_TXN_STATUS_PACK:
        path = path + "PENDINGACKNOWLEDGEMENTNOTIFICATION.png";
        alt = "PENDINGACKNOWLEDGEMENTNOTIFICATION.png";
        break;
      case MASTER_TXN_STATUS_REQ:
        path = path + "REQUESTED.png";
        alt = "REQUESTED.png";
        break;
      case MASTER_TXN_STATUS_COM:
        path = path + "COMPLETED.png";
        alt = "COMPLETED.png";
        break;
      case MASTER_TXN_STATUS_PVLN:
        path = path + "PENDINGVALIDATIONNOTIFICATION.png";
        alt = "PENDINGVALIDATIONNOTIFICATION.png";
        break;
      case MASTER_TXN_STATUS_PACN:
        path = path + "PENDINGACKNOWLEDGEMENTNOTIFICATION.png";
        alt = "PENDINGACKNOWLEDGEMENTNOTIFICATION.png";
        break;
      case MASTER_TXN_STATUS_PVLR:
        path = path + "PENDINGVALIDATIONREQUEST.png";
        alt = "PENDINGVALIDATIONREQUEST.png";
        break;
      case MASTER_TXN_STATUS_REJN:
        path = path + "REJECTEDNOTIFICATION.png";
        alt = "REJECTEDNOTIFICATION.png";
        break;
      case MASTER_TXN_STATUS_REJR:
        path = path + "REJECTEDREQUEST.png";
        alt = "REJECTEDREQUEST.png";
        break;
      //EQL: SiteAccess after receiving rejected tranack
      case MASTER_TXN_STATUS_REJ:
        path = path + "REJECTEDNOTIFICATION.png";
        alt = "REJECTEDNOTIFICATION.png";
        break;
    }
    return <Image src={path} alt={alt} size="100" height="auto" data-test="nz-switch-cancelled-txn-flow"></Image>;
  };

  const gasImageSelector = () => {
    if (
      txnGroup === TXN_GROUP_CATS ||
      txnGroup === TXN_GROUP_CUST ||
      txnGroup === TXN_GROUP_FLDW ||
      txnGroup === TXN_GROUP_IAIT ||
      txnGroup === TXN_GROUP_NETB ||
      txnGroup === TXN_GROUP_NMID ||
      txnGroup === TXN_GROUP_SITE
    ) {
      return gasCatsTxnFlowRenderer(txnStatus);
    } else {
      return gasMtrdTxnFlowRenderer(txnStatus);
    }
  };

  const gasCatsTxnFlowRenderer = (txnStatus) => {
    let path = "/images/AU/GAS/CATS/";
    let alt = "";
    switch (txnStatus.toUpperCase()) {
      case MASTER_TXN_STATUS_PACK:
        path = path + "PENDINGACKNOWLEDGEMENT.png";
        alt = "PENDINGACKNOWLEDGEMENT.png";
        break;
      case MASTER_TXN_STATUS_PVAL:
        path = path + "PENDINGVALIDATION.png";
        alt = "PENDINGVALIDATION.png";
        break;
      case MASTER_TXN_STATUS_REQ:
        path = path + "REQUESTED.png";
        alt = "REQUESTED.png";
        break;
      case MASTER_TXN_STATUS_PEN:
        path = path + "PENDINGAPPROVED.png";
        alt = "PENDINGAPPROVED.png";
        break;
      case MASTER_TXN_STATUS_COM:
        path = path + "COMPLETED.png";
        alt = "COMPLETED.png";
        break;
      case MASTER_TXN_STATUS_REJ:
        path = path + "REJECTED.png";
        alt = "REJECTED.png";
        break;
      case MASTER_TXN_STATUS_OBJ:
        path = path + "OBJECTED.png";
        alt = "OBJECTED.png";
        break;
      case MASTER_TXN_STATUS_RDF:
        path = path + "READFAILURE.png";
        alt = "READFAILURE.png";
        break;
      case MASTER_TXN_STATUS_CAN:
        path = path + "CANCELLED.png";
        alt = "CANCELLED.png";
        break;
    }
    return <Image src={path} alt={alt} size="100" height="auto" data-test="nz-switch-cancelled-txn-flow"></Image>;
  };

  const gasMtrdTxnFlowRenderer = (txnStatus) => {
    let path = "/images/AU/GAS/MTRD/";
    let alt = "";
    switch (txnStatus.toUpperCase()) {
      case MASTER_TXN_STATUS_CAN:
        path = path + "CANCELLED.png";
        alt = "CANCELLED.png";
        break;
      case MASTER_TXN_STATUS_COM:
        path = path + "COMPLETED.png";
        alt = "COMPLETED.png";
        break;
      case MASTER_TXN_STATUS_NACC:
        path = path + "NOACCESS.png";
        alt = "NOACCESS.png";
        break;
      case MASTER_TXN_STATUS_PACK:
        path = path + "PENDINGACKNOWLEDGEMENT.png";
        alt = "PENDINGACKNOWLEDGEMENT.png";
        break;
      case MASTER_TXN_STATUS_REJ:
        path = path + "REJECTED.png";
        alt = "REJECTED.png";
        break;
      case MASTER_TXN_STATUS_REQ:
        path = path + "REQUESTED.png";
        alt = "REQUESTED.png";
        break;
      case MASTER_TXN_STATUS_PADD:
        path = path + "PENDINGADDITIONALDATA.png";
        alt = "PENDINGADDITIONALDATA.png";
        break;
    }
    return <Image src={path} alt={alt} size="100" height="auto" data-test="nz-switch-cancelled-txn-flow"></Image>;
  };

  return (
    <Row className="status-image-row master-txn-wrapper-child" data-test="nz-display-txn-flow-status-row">
      <Col style={{ textAlign: "center" }}>
        <div style={{ textAlign: "left" }}>
          <strong>{createTitle()}</strong>
        </div>
        {transactionTypeImageSelector()}
      </Col>
    </Row>
  );
};

export default AUTxnFlowDiagram;
