import { Button, Col, Container, Image, Row } from "react-bootstrap";
import HeaderSignIn from "../Authentication/HeaderSignIn/HeaderSignIn";
import FooterSignIn from "../Authentication/FooterSignIn/FooterSignIn";
import "./Unauthorised.css";
import { log } from "../../../utils/ALL/loggingUtils";
import { Auth } from "aws-amplify";
const Unauthorised = () => {
  const handleClick = async (e) => {
    e.preventDefault();

    await Auth.signOut();
  };

  return (
    <div className="div-sign-in-wrapper">
      <HeaderSignIn></HeaderSignIn>
      <Container className="unauthorised-container mt-5 mb-5">
        <Row className="unauthorised-first-row">
          <Col>
            <Image src="/images/palm.png" className="palm-picture"></Image> Unauthorised
          </Col>
        </Row>
        <Row className="unauthorised-second-row">
          <Col>You currently do not have access to this page. Please contact your system administrator for access.</Col>
        </Row>
        <Row className="unauthorised-third-row">
          <Col>
            <Button className="unauthorised-go-back-button" onClick={handleClick}>
              Go Back to Homepage
            </Button>
          </Col>
        </Row>
      </Container>
      <FooterSignIn></FooterSignIn>
    </div>
  );
};

export default Unauthorised;
